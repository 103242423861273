import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import { CrudDataFilterOption } from 'platform-unit2-api/core';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Locale } from 'platform-unit2-api/locales';
import { ModuleDetail } from 'platform-unit2-api/modules';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Product, UpdateProductField, ErrorProductFieldData } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';

export interface AttributeViewFields extends ProductField {
  deleted: boolean;
}

export interface LocaleDetails extends Locale {
  language: string;
  region?: string;
  format: string;
}

export interface ProductUpload extends Upload {
  order: number;
  include_in_export: boolean;
  raw_response: string;
}

export interface TableColumn {
  selected: boolean;
  field: string;
  header: string;
  sortable?: boolean;
  filter?: boolean;
  type: string;
  size: string;
  visible?: boolean;
}

export interface ProductsState {
  products?: ListResponse<Product>;
  loadingProduct?: number;
  currentProduct?: Product;
  nextProduct?: Product;
  previousProduct?: Product;
  currentResetProduct?: Product;
  editingProductFields: UpdateProductField[];
  loadingCompleteness: boolean;
  errorProductFields: ErrorProductFieldData[];
  brandFilter: CrudDataFilterOption[];
  dataModelFilter: CrudDataFilterOption[];
  statusFilter: CrudDataFilterOption[];
  moduleFilter: CrudDataFilterOption[];
  gtinFilter: string[];
  variantFilter: CrudDataFilterOption[];
  categoryFilter: CrudDataFilterOption[];
  ownerFilter: CrudDataFilterOption[];
  idFilter: number[];
  search: string;
  overviewPagination: PaginationObject;
  columns: TableColumn[];
  currentProductDatamodels: Datamodel[];
  currentProductVariants: Product[];
  initialized: boolean;
  showMissingFields: boolean;
  selectedLocales: Locale[];
  copyFromLanguage: boolean;
  fields: ProductField[];
  glnFilter: number[];
  imagesIncluded: Upload[];
  shouldRunAttributesValidation: boolean;
  validationErrors: ErrorProductFieldData[];
}
export interface ProductCalendarQuery {
  date: string;
  type: string;
}

export interface ProductLinkedChild {
  product_id: number;
  display_name: string;
  quantity: number;
}

export enum PageIndex {
  CHANNEL_VIEW_PAGE = 0,
  SETTING_EXPORT_VIEW_PAGE = 1,
  SCHEDULE_VIEW_PAGE = 2,
  CONTACT_VIEW_PAGE = 3,
  INFO_EXPORT_VIEW_PAGE = 4,

  //Generic Flow
  GENERIC_VIEW_PAGE = 0,
  PRODUCT_OVERVIEW_VIEW_PAGE = 1,
}
export interface AdvancedExportSettings {
  data: boolean;
  assets: boolean;
}

export interface CreateExport {
  pageIndex?: number;
  mailTo: string[];
  customMessage: string;
  selectedModule: ModuleDetail;
  product_ids: number[];
  selectedLocale: LocaleDetails;
  changed_products: {
    beforeDate?: Date;
    afterDate?: Date;
    afterExport: boolean;
  };
  includeData: boolean;
  includeAssets: boolean;
  exportName: string;
  exportCron: string;
  isScheduled: boolean;
  scheduleType: 'Daily' | 'Weekly' | 'Monthly';
  dailySchedule: number;
  time: Date;
  weekDays: number[];
  daysOfMonth: string[];
  exportTypeIsGeneric: boolean;
  products: Product[];
  useOverwrittenNames?: boolean;
  ignoreProductStatus?: boolean;
}
export interface LanguageMenu {
  label: string;
  icon: string;
  value: Locale;
  command: (e: any) => void;
}

export interface ProductDatamodelSelectModel extends Datamodel {
  hide_options: boolean;
}
