<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { scheduledExportServiceKey } from '../ts/keys/exports.keys';
import { inject, ref } from 'vue';

//Types
import { ScheduledPipeline } from 'platform-unit2-api/scheduled-pipelines';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { MenuItem } from 'primevue/menuitem';
import ExportScheduledProductList from 'supplier/modules/export/components/export-scheduled-product-list.vue';
import { formatDate } from '@/general/utils/format-date';

const ts = inject(tsKey)!;
const scheduledExportService = inject(scheduledExportServiceKey)!;

const expandedRows = ref([]);

/* Menu implementation */
const menuItems = (scheduledPipeline: ScheduledPipeline): MenuItem[] => [
  {
    label: ts.value.tModule('tooltips.restart'),
    icon: 'mdi mdi-restart',
    command: () => {
      scheduledExportService.value.restartScheduledPipeline(scheduledPipeline.id!);
    },
  },
  {
    label: ts.value.tModule('tooltips.start'),
    icon: 'mdi mdi-play-circle-outline',
    visible: () => !!scheduledPipeline.is_paused,
    command: () => {
      scheduledExportService.value.changePipelineStatus(scheduledPipeline, false);
    },
  },
  {
    label: ts.value.tModule('tooltips.pause'),
    icon: 'mdi mdi-pause-circle-outline',
    visible: () => !scheduledPipeline.is_paused,
    command: () => {
      scheduledExportService.value.changePipelineStatus(scheduledPipeline, true);
    },
  },
  {
    label: ts.value.tModule('tooltips.edit'),
    icon: 'mdi mdi-pencil-outline',
    command: () => {
      scheduledExportService.value.openCrudComponent(scheduledPipeline);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.value.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      scheduledExportService.value.confirmScheduledPipelineDelete(scheduledPipeline);
    },
  },
];
</script>
<template>
  <pDataTable
    v-model:expanded-rows="expandedRows"
    auto-layout
    scrollable
    :loading="scheduledExportService.isLoadingOverView"
    filter-display="menu"
    scroll-height="flex"
    :value="scheduledExportService.scheduledPipelines"
    lazy
    :paginator="
      scheduledExportService.limit
        ? (scheduledExportService.total ?? 0) > scheduledExportService.limit
        : false
    "
    :rows="scheduledExportService.limit"
    :total-records="scheduledExportService.total"
    :first="scheduledExportService.first"
    @page="scheduledExportService.onPageChange($event.page)"
  >
    <pColumn
      class="flex-none"
      body-style="width: 5rem"
      :expander="true"
      header-style="width: 5rem"
    />
    <!--#region Columns-->
    <pColumn field="name" :header="ts.tModule('table_header.name')" />

    <pColumn field="client" :header="ts.tModule('table_header.workspace')">
      <template #body="slotProps">
        {{ slotProps.data.client ? slotProps.data.client.name : 'Productsup' }}
      </template>
    </pColumn>

    <pColumn field="user" :header="ts.tModule('table_header.user')">
      <template #body="slotProps">
        {{
          slotProps.data?.user?.id === scheduledExportService.currentUser?.id
            ? ts.tGlobal('me')
            : slotProps.data.user.name
        }}
      </template>
    </pColumn>

    <pColumn field="status" :header="ts.tModule('table_header.status')">
      <template #body="slotProps">
        <div class="flex">
          <template v-if="slotProps.data.is_paused">
            <StatusChip :label="ts.tModule('export_scheduled.paused')" severity="paused" />
          </template>
          <template v-else>
            <StatusChip :label="ts.tModule('export_scheduled.running')" severity="success" />
          </template>
        </div>
      </template>
    </pColumn>

    <pColumn field="cron" :header="ts.tModule('table_header.next_at')">
      <template #body="slotProps">
        {{ formatDate(new Date(scheduledExportService.getNextRun(slotProps.data.cron))) }}
      </template>
    </pColumn>

    <pColumn field="icons" class="flex-none" header-style="width: 5rem" body-style="width: 5rem">
      <template #body="slotProps">
        <ActionsMenu :menu-items="menuItems(slotProps.data)" />
      </template>
    </pColumn>

    <template #empty>
      <EmptyState
        :empty-state-title="
          ts.tGlobal('emptyStateTitle', {
            entity: ts.tModule('export_scheduled.title', { choice: 2 }).toLowerCase(),
          })
        "
        :translation-service="ts"
        button-visible
        :button-label="ts.tGlobal('productOverview')"
        :icon-name="'scheduled-exports'"
        @clicked="$router.push({ name: 'products' })"
      />
    </template>
    <template #expansion="slotProps">
      <div class="w-full">
        <ExportScheduledProductList :product-ids="slotProps.data.product_ids" />
        <!-- This functionality is not yet implemented, therefore button is disabled for now -->
        <div v-if="false" class="flex justify-content-end">
          <pButton rounded disabled icon="mdi mdi-plus" label="Add more products" />
        </div>
      </div>
    </template>
  </pDataTable>
</template>
