<script lang="ts" setup>
import { useService } from '@/general/composables/useService/useService';
import { scheduledImportServiceKey } from '../ts/keys/scheduled-import-service.key';
import { ScheduledImportViewService } from '@/general/services/overview-service/modules/scheduled-import-view.service';
import { computed } from 'vue';
import { User } from 'platform-unit2-api/users';
import store from '@/core/store';
import { TranslationService } from '@/general/services/translations/translation.service';
import * as cronParser from 'cron-parser';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import { ScheduledPipeline } from 'platform-unit2-api/scheduled-pipelines';
import { MenuItem } from 'primevue/menuitem';
import EmptyState from '@/general/ui/components/empty-state.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import { formatDate } from '@/general/utils/format-date';

const service = useService(scheduledImportServiceKey, ScheduledImportViewService);
const ts = new TranslationService('supplier', 'imports');
const currentUser = computed((): User => {
  return store.getters['users/currentUser'];
});

const getNextRun = (cron: string): string => {
  return cronParser.parseExpression(cron).next().toISOString();
};

const menuItems = (scheduledPipeline: ScheduledPipeline): MenuItem[] => [
  {
    label: ts.tModule('tooltip.restart'),
    icon: 'mdi mdi-restart',
    command: () => {
      service.restartScheduledPipeline(scheduledPipeline.id);
    },
  },
  {
    label: ts.tModule('tooltip.start'),
    icon: 'mdi mdi-play-circle-outline',
    visible: scheduledPipeline?.is_paused,
    command: () => {
      service.togglePipelineRunningState(scheduledPipeline.id, false);
    },
  },
  {
    label: ts.tModule('tooltip.pause'),
    icon: 'mdi mdi-pause-circle-outline',
    visible: !scheduledPipeline?.is_paused,
    command: () => {
      service.togglePipelineRunningState(scheduledPipeline.id, true);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      service.deleteAction(scheduledPipeline.id);
    },
  },
];
</script>
<template>
  <pDataTable
    scrollable
    scroll-height="flex"
    :value="service.scheduledImports"
    removable-sort
    :row-hover="true"
    :lazy="true"
    :paginator="service.total > (service.limit ?? 0)"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    @row-click="service.openCrudComponent($event.data)"
    @page="service.onPageChange($event.page)"
    @sort="service.onSortChange($event)"
  >
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-visible="true"
        :button-icon="'mdi mdi-plus'"
        :icon-name="'scheduled-imports'"
        @clicked="service.openCrudComponent()"
      />
    </template>
    <p-column field="name" :header="ts.tGlobal('name')" />

    <p-column field="client" :header="ts.tGlobal('workspace')">
      <template #body="slotProps">
        {{ slotProps.data.client ? slotProps.data.client.name : ts.tGlobal('pup') }}
      </template>
    </p-column>

    <p-column field="user" header="User">
      <template #body="slotProps">
        {{
          currentUser && slotProps.data.user.id === currentUser.id
            ? ts.tModule('me')
            : slotProps.data.user.name
        }}
      </template>
    </p-column>

    <p-column field="status" :header="ts.tGlobal('status')">
      <template #body="slotProps">
        <div class="flex">
          <template v-if="slotProps.data.module == null">
            <StatusChip :label="ts.tModule('import_scheduled.missing_channel')" severity="danger" />
          </template>
          <template v-else-if="slotProps.data.is_paused">
            <StatusChip :label="ts.tModule('import_scheduled.paused')" severity="paused" />
          </template>
          <template v-else>
            <StatusChip :label="ts.tModule('import_scheduled.running')" severity="success" />
          </template>
        </div>
      </template>
    </p-column>

    <p-column field="mapping" :header="ts.tModule('table_headers.mapping')">
      <template #body="slotProps">
        <span v-if="slotProps.data.mapping"> {{ slotProps.data.mapping.name }}</span>
        <span
          v-else
          v-tooltip.bottom="{
            value: ts.tModule('import_scheduled.mapping_tooltip'),
            class: 'text-sm',
          }"
          class="text-red-600"
        >
          {{ ts.tGlobal('none') }}</span
        >
      </template>
    </p-column>

    <p-column field="cron" :header="ts.tModule('import_scheduled.next_import_at')">
      <template #body="slotProps">
        {{ formatDate(new Date(getNextRun(slotProps.data.cron))) }}
      </template>
    </p-column>

    <p-column field="icons">
      <template #body="slotProps">
        <div class="flex justify-content-end">
          <ActionsMenu :menu-items="menuItems(slotProps.data)" />
        </div>
      </template>
    </p-column>
  </pDataTable>
</template>
