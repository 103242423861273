<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import IncompleteProductFields from 'supplier/modules/incomplete-products/components/incomplete-product-fields.vue';
import usePagination from 'composables/usePagination/pagination';
import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DataTablePageEvent } from 'primevue/datatable';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import {
  IncompleteProductsRestService,
  IncompleteProductsAuditorEnum,
} from 'platform-unit2-api/incomplete-products';
import { Product } from 'platform-unit2-api/products';
import { isNegative } from '@/general/utils/isNegative';
import { formatDate } from '@/general/utils/format-date';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'incompleteProducts');
const incompleteProductsApi = new IncompleteProductsRestService();

// Constants
const route = useRoute();
const { page, perPage, onPageChange: $onPageChange } = usePagination();
const loading = ref(true);

const expandedRowGroups = ref<Array<string>>([]);

const products = ref<Product[]>([]);
const total = computed(() => {
  return products.value.length;
});

const loadAsyncData = async (showLoadingIcon = true): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.getFailed());
    return;
  }

  loading.value = showLoadingIcon;
  try {
    let auditor;
    if (route.name === 'retailer-products-incomplete') {
      auditor = IncompleteProductsAuditorEnum.RETAILERS;
    } else if (route.name === 'brand-products-incomplete') {
      auditor = IncompleteProductsAuditorEnum.BRANDS;
    } else if (route.name === 'module-products-incomplete') {
      auditor = IncompleteProductsAuditorEnum.MODULES;
    } else {
      throw Error;
    }

    const response = await incompleteProductsApi.getIncompleteProductsForAuditor(
      auditor,
      Number(route.params.id),
      page.value,
      perPage.value,
    );

    products.value = response.data;
  } catch (err) {
    toastService.displayErrorToast(ts.getFailed());
  }

  loading.value = false;
};

const getVariantName = (value?: string) => {
  if (value == null) {
    return 'Master Data';
  }

  return value;
};

const closeRow = () => {
  expandedRowGroups.value = [];
  loadAsyncData();
};

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

onMounted(() => {
  loadAsyncData();
});
</script>

<template>
  <section class="flex flex-column h-full pt-4 px-4">
    <div v-if="!(total || loading)">
      <p-message severity="info">{{ ts.notFoundWarning }}</p-message>
    </div>
    <pDataTable
      v-model:expanded-rows="expandedRowGroups"
      scrollable
      scroll-height="flex"
      data-key="id"
      :value="products"
      :loading="loading"
      :paginator="total > perPage"
      :rows="perPage"
      :total-records="total"
      :first="(page - 1) * perPage"
      @page="(event: DataTablePageEvent) => onPageChange(event)"
    >
      <p-column
        class="flex-none"
        body-style="width: 5rem"
        :expander="true"
        header-style="width: 5rem"
      />
      <p-column header-style="width: 6rem" field="thumbnail" :header="ts.tGlobal('thumbnail')">
        <template #body="slotProps">
          <upload :key="slotProps.data.id" :upload="slotProps.data.thumbnail" />
        </template>
      </p-column>
      <p-column field="display_name" :header="ts.tModule('product_name')">
        <template #body="slotProps">
          {{
            slotProps.data.display_name + ' (' + getVariantName(slotProps.data.variant_name) + ')'
          }}
        </template>
      </p-column>
      <p-column field="category.name" :header="ts.tGlobal('category')" />
      <p-column field="completeness" :header="ts.tGlobal('completeness_score')">
        <template #body="slotProps">
          <ProgressBar :progress="Math.round(slotProps.data.completeness) ?? 0" width="w-7rem" />
        </template>
      </p-column>
      <p-column field="updated_at" :header="ts.tGlobal('lastUpdate')">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.updated_at) }}
        </template>
      </p-column>
      <template #expansion="slotProps">
        <div class="orders-subtable">
          <IncompleteProductFields
            :id="parseInt(route.params.id?.[0] ?? route.params.id)"
            :product="slotProps.data"
            @close="closeRow"
          />
        </div>
      </template>
    </pDataTable>
  </section>
</template>
