import { CrudDataFilterOption } from 'platform-unit2-api/core';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Locale } from 'platform-unit2-api/locales';
import { ProductField } from 'platform-unit2-api/product-fields';
import { Product, UpdateProductField, ErrorProductFieldData } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import { GetterTree } from 'vuex';
import { ProductsState, TableColumn } from '../products.types';

export const getters: GetterTree<ProductsState, {}> = {
  products(state: ProductsState): ListResponse<Product> | undefined {
    return state.products;
  },

  currentProduct(state: ProductsState): Product | undefined {
    return state.currentProduct;
  },

  loadingProduct(state: ProductsState): number | undefined {
    return state.loadingProduct;
  },

  nextProduct(state: ProductsState): Product | undefined {
    return state.nextProduct;
  },

  previousProduct(state: ProductsState): Product | undefined {
    return state.previousProduct;
  },

  currentResetProduct(state: ProductsState): Product | undefined {
    return state.currentResetProduct;
  },

  editingProductFields(state: ProductsState): UpdateProductField[] {
    return state.editingProductFields;
  },

  errorProductFields(state: ProductsState): ErrorProductFieldData[] {
    return state.errorProductFields;
  },

  loadingCompleteness(state: ProductsState): boolean {
    return state.loadingCompleteness;
  },

  brandFilter(state: ProductsState): CrudDataFilterOption[] {
    return state.brandFilter;
  },

  gtinFilter(state: ProductsState): string[] {
    return state.gtinFilter;
  },

  glnFilter(state: ProductsState): number[] {
    return state.glnFilter;
  },

  categoryFilter(state: ProductsState): CrudDataFilterOption[] {
    return state.categoryFilter;
  },

  dataModelFilter(state: ProductsState): CrudDataFilterOption[] {
    return state.dataModelFilter;
  },

  variantFilter(state: ProductsState): CrudDataFilterOption[] | undefined {
    return state.variantFilter;
  },

  ownerFilter(state: ProductsState): CrudDataFilterOption[] {
    return state.ownerFilter;
  },

  moduleFilter(state: ProductsState): CrudDataFilterOption[] {
    return state.moduleFilter;
  },

  statusFilter(state: ProductsState): CrudDataFilterOption[] {
    return state.statusFilter;
  },
  idFilter(state: ProductsState): number[] | undefined {
    return state.idFilter;
  },

  overviewPagination(state: ProductsState): PaginationObject {
    return state.overviewPagination;
  },

  columns(state: ProductsState): TableColumn[] {
    return state.columns;
  },

  getSearch(state: ProductsState): string | undefined {
    return state.search;
  },

  currentProductDatamodels(state: ProductsState): Datamodel[] | undefined {
    return state.currentProductDatamodels;
  },

  currentProductVariants(state: ProductsState): Product[] | undefined {
    return state.currentProductVariants;
  },

  initialized(state: ProductsState): boolean {
    return state.initialized;
  },

  showMissingFields(state: ProductsState): boolean {
    return state.showMissingFields;
  },

  selectedLocales(state: ProductsState): Locale[] {
    return state.selectedLocales;
  },

  copyfromLanguage(state: ProductsState): boolean {
    return state.copyFromLanguage;
  },

  fields(state: ProductsState): ProductField[] {
    return state.fields;
  },
  imagesIncluded(state: ProductsState): Upload[] {
    return state.imagesIncluded;
  },

  shouldRunAttributesValidation(state: ProductsState): boolean {
    return state.shouldRunAttributesValidation;
  },

  validationErrors(state: ProductsState): ErrorProductFieldData[] {
    return state.validationErrors;
  },
};
