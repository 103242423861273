import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Download | Downloads',
  download_ready: 'Your files are ready!',
  sender_message: '{name} from the {workspace} workspace has send you some files.',
  loading_zip: 'Generating zip file. Please be patient.',
  downloading: 'Downloading...',
  download_all: 'Download all {amount} files',
  help_text: 'Having trouble?  <span class="underline cursor-pointer">Send us a message</span>',
  no_downloads_title: 'Oops, looks like something went wrong!',
  no_downloads_subtitle: 'Please check the Export or contact our support team',
  go_to_exports: 'Return to Exports',
  start_direct_download: 'If your download has not yet started, please click ',
  start_direct_download_link: 'here',
  start_direct_download_end: ' to download.',
};

const tranlation: Translation = {
  downloads: translationObject,
};

export default tranlation;
