<script setup lang="ts">
import { inject } from 'vue';
import { serviceKey } from '../ts/keys/service-key';
import { PageState } from 'primevue/paginator';
import UserAvatar from 'ui/components/user-avatar.vue';
import { useRouter } from 'vue-router';
import EmptyState from 'ui/components/empty-state.vue';

const service = inject(serviceKey)!;
const router = useRouter();
</script>
<template>
  <div class="grid mb-4">
    <div
      v-for="user in service.users"
      :key="user?.id"
      class="col-12 flex flex-column lg:col-3 md:col-6 p-2"
      @click.stop="router.push({ name: 'edit-user', params: { id: user.id } })"
    >
      <pCard class="card clickable h-full">
        <template #content>
          <div
            class="align-items-center flex flex-column h-18rem justify-content-center m-auto max-w-full w-18rem"
          >
            <UserAvatar
              :url="user.thumbnail?.public_thumbnail_url ?? user.thumbnail?.public_url"
              :username="user?.name"
              size="xlarge"
            />
            <p class="font-bold mt-3 text-2xl">
              {{ user.name }}
            </p>
            <div class="mt-3">
              <p class="capitalize">
                {{ user.roles[0]?.name }}
              </p>
            </div>
          </div>
        </template>
      </pCard>
    </div>
  </div>
  <div>
    <EmptyState
      v-if="service.isSearchResultEmpty"
      :translation-service="service.ts"
      :icon-name="'users'"
      :empty-state-subtitle="service.ts.tModule('emptySearchStateSubTitle')"
      :button-label="service.ts.createButton"
      :button-icon="'mdi mdi-plus'"
      @clicked="service?.openCrudComponent()"
    />
    <EmptyState
      v-if="service.hasNoUsers"
      :translation-service="service.ts"
      :icon-name="'users'"
      :button-label="service.ts.createButton"
      :button-icon="'mdi mdi-plus'"
      @clicked="service?.openCrudComponent()"
    />
  </div>
  <pPaginator
    v-if="service.users.length > 0 && service.total > (service.limit ?? 0)"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    :pt="{
      paginatorWrapper: {
        class: 'pagination-centered',
      },
    }"
    @page="(event: PageState) => service.onPageChange(event.page)"
  />
</template>
<style lang="scss" scoped>
.pagination-centered {
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
}
</style>
