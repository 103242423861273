<script setup lang="ts">
import RoleSelect from '@/general/ui/components/selects/role-select.vue';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { serviceKey } from '../ts/keys/service-key';

const ts = new TranslationService('supplier', 'users');
const service = inject(serviceKey)!;
</script>
<template>
  <div class="field mb-3">
    <label for="name">{{ ts.tForms('name') }}</label>

    <pInputText
      v-model="service.partialObject.name"
      class="w-full"
      :class="{ 'p-invalid': service?.hasError('name') }"
      type="text"
    />
    <small
      v-if="service?.hasError('name')"
      :class="{ 'p-error block': service?.hasError('name') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('name').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="email">{{ ts.tForms('email') }}</label>
    <pInputText
      v-model="service.partialObject.email"
      class="w-full"
      type="email"
      :disabled="!service.isCreating"
      :class="{ 'p-invalid': service?.hasError('email') }"
    />
    <small
      v-if="service?.hasError('email')"
      :class="{ 'p-error block': service?.hasError('email') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('email').toString() ?? '' }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="logo">{{ ts.tGlobal('avatar') }}</label>
    <div class="align-items-center flex formgroup-inline">
      <ThumbnailUploader
        :is-avatar="service.partialObject.thumbnail == null"
        :thumbnail="service.partialObject.thumbnail"
        @uploaded="
          (value) => ((service.partialObject.thumbnail = value), (service.uploading = false))
        "
        @uploading="() => (service.uploading = true)"
      />
    </div>
  </div>
  <div class="field mb-3">
    <RoleSelect v-model="service.role" :class="{ 'p-invalid': service?.hasError('roles') }" />
    <small
      v-if="service?.hasError('roles')"
      :class="{ 'p-error block': service?.hasError('roles') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('roles').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="mobile">{{ ts.tForms('mobile') }}</label>
    <pInputText
      id="mobile"
      v-model="service.partialObject.mobilephone"
      class="w-full"
      type="text"
      :class="{ 'p-error block': service?.hasError('mobilephone') }"
    />
    <small
      v-if="service?.hasError('mobilephone')"
      :class="{ 'p-error block': service?.hasError('mobilephone') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('mobilephone').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="address">{{ ts.tForms('address') }}</label>
    <pInputText
      id="address"
      v-model="service.partialObject.address"
      class="w-full"
      type="text"
      :class="{ 'p-error block': service?.hasError('address') }"
    />
    <small
      v-if="service?.hasError('address')"
      :class="{ 'p-error block': service?.hasError('address') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('adress').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="region">{{ ts.tForms('country') }}</label>
    <pInputText
      id="region"
      v-model="service.partialObject.country"
      class="w-full"
      type="text"
      :class="{ 'p-error block': service?.hasError('country') }"
    />
    <small
      v-if="service?.hasError('country')"
      :class="{ 'p-error block': service?.hasError('country') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('country').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <label for="position">{{ ts.tForms('position') }}</label>
    <pInputText
      id="position"
      v-model="service.partialObject.position"
      class="w-full"
      type="text"
      :class="{ 'p-error block': service?.hasError('position') }"
    />
    <small
      v-if="service?.hasError('position')"
      :class="{ 'p-error block': service?.hasError('position') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('position').toString() }}</small
    >
  </div>
  <div class="field mb-3">
    <LocaleSelect
      v-model="service.partialObject.locale"
      :class="{ 'p-error block': service?.hasError('locale_id') }"
    />
    <small
      v-if="service?.hasError('locale_id')"
      :class="{ 'p-error block': service?.hasError('locale_id') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('locale_id').toString() }}</small
    >
  </div>
  <div class="field">
    <ClientSelect
      v-if="service?.partialObject"
      v-model="service.partialObject.clients"
      multiselect
      :class="{ 'p-invalid': service?.hasError('client_ids') }"
    />
    <small
      v-if="service?.hasError('client_ids')"
      :class="{ 'p-error block': service?.hasError('client_ids') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('client_ids').toString() }}</small
    >
  </div>

  <div class="field">
    <ClientSelect
      v-model="service.partialObject.workspace"
      :options="service?.partialObject.clients"
      :class="{ 'p-invalid': service?.hasError('workspace_id') }"
    />
    <small
      v-if="service?.hasError('workspace_id')"
      :class="{ 'p-error block': service?.hasError('workspace_id') }"
      class="hidden"
      >{{ service?.fieldErrorMessage('workspace_id').toString() }}</small
    >
  </div>
</template>
