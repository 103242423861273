<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { deletedProductsServiceKey } from '../../ts/keys/products.keys';
import { inject } from 'vue';
import { Product } from 'platform-unit2-api/products';

const ts = inject(tsKey)!;
const service = inject(deletedProductsServiceKey)!;
</script>

<template>
  <pDataTable
    :value="service.deletedProducts"
    lazy
    scrollable
    scroll-height="flex"
    :paginator="service.limit ? (service.total ?? 0) > service.limit : false"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    @page="service.onPageChange($event.page)"
  >
    <template #empty>
      <EmptyState
        style="max-width: 80vw"
        class="pt-5"
        :translation-service="ts"
        :icon-name="'products'"
        :button-visible="false"
        :empty-state-subtitle="ts.tModule('deletedProducts.emptyStateSubtitle')"
      />
    </template>

    <pColumn field="gtin" :header="ts.tModule('export_modal.product_overview.column_gtin')" />
    <pColumn
      field="display_name"
      :header="ts.tModule('export_modal.product_overview.column_product_name')"
    />
    <pColumn
      field="datamodel.name"
      :header="ts.tModule('export_modal.product_overview.column_data_model')"
    />
    <pColumn field="variant_name" :header="ts.tModule('variant_name')">
      <template #body="slotProps: { data: Product }">
        {{ slotProps.data.variant_name || 'Master Data' }}
      </template></pColumn
    >
    <pColumn field="brand.name" :header="ts.tGlobal('brand')">
      <template #body="slotProps: { data: Product }">
        {{ slotProps.data.brand?.name || '-' }}
      </template></pColumn
    >
    <pColumn :header="ts.tModule('deletedProducts.restore')">
      <template #body="slotProps: { data: Product }">
        <pButton
          v-tooltip.top="
            !slotProps.data.datamodel ? ts.tModule('deletedProducts.restoreTooltip') : ''
          "
          icon="mdi mdi-arrow-u-left-top"
          class="ml-5"
          text
          :disabled="!slotProps.data.datamodel"
          @click="service.requestRestoreProduct(slotProps.data)"
        />
      </template>
    </pColumn>
  </pDataTable>
</template>
