<script setup lang="ts">
import RoleSelect from '@/general/ui/components/selects/role-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import { FeaturePermission, UserPermission } from 'platform-unit2-api/roles';
import { Client } from 'platform-unit2-api/clients';
import useFormValidation from 'composables/form-validation';
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { UpdateUserRequest, User } from 'platform-unit2-api/users';
import { RoleCompact } from 'platform-unit2-api/roles';
import { authorizationMethods } from '@/general/composables/authorization.plugin';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { UserRestService } from 'platform-unit2-api/users';
import { DirtyStateService } from '@/general/services/dirty-state/dirty-state.service';
import { onBeforeRouteLeave } from 'vue-router';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';

/** Composables */
const store = useStore();
const { fieldErrorMessage, hasError } = useFormValidation();
const { hasPermission, hasUserPermission } = authorizationMethods;

const saving = ref(false);
const initialUser = ref<User>();
const loading = ref(true);

/* Services */
const ts = new TranslationService('supplier', 'settings');
const toastService = ToastService.getInstance();
const userApi = new UserRestService();
const dirtyStateService = new DirtyStateService<User>();

const init = async () => {
  loading.value = true;
  if (store.getters['users/currentUser']?.id == null) return;

  initialUser.value = await userApi.get(store.getters['users/currentUser'].id);
  dirtyStateService.changeInitialData(initialUser.value);
  dirtyStateService.changeDataToCompare(initialUser.value);

  loading.value = false;
};

const updateUser = async (): Promise<void> => {
  saving.value = true;
  try {
    if (initialUser.value == null) return;

    const updatedUser: UpdateUserRequest = {
      id: initialUser.value.id,
      name: initialUser.value.name ?? '',
      email: initialUser.value.email ?? '',
      mobilephone: initialUser.value.mobilephone ?? '',
      address: initialUser.value.address ?? '',
      thumbnail_id: initialUser.value.thumbnail?.id ?? null,
      position: initialUser.value.position,
      workspace_id: initialUser.value.workspace?.id ?? 0,
      country: initialUser.value.country,
      roles: initialUser.value.roles.map((role: RoleCompact) => role.id) ?? [],
      client_ids: initialUser.value.clients.map((client: Client) => client.id) ?? [],
      locale_id: initialUser.value.locale ? initialUser.value?.locale.id : undefined,
    };

    await store.dispatch('users/UPDATE_USER', {
      id: initialUser.value!.id,
      updatedUser: updatedUser,
    });

    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    saving.value = false;
    dirtyStateService.changeInitialData(initialUser.value!);
  }
};

onBeforeRouteLeave((_, __, next) => {
  dirtyStateService.showDirtyDialog(next, undefined, next);
});

watch(
  () => store.getters['users/currentUser']?.id,
  () => {
    init();
  },
),
  onMounted(async () => {
    await init();
  });
</script>

<template>
  <section class="h-full pt-3 px-4">
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <div class="formgrid grid">
        <div v-if="initialUser" class="col-12 field lg:col-6">
          <label for="name">{{ ts.tForms('name') }}</label>
          <p-input-text id="name" v-model="initialUser.name" class="w-full" type="text" />
          <small v-if="fieldErrorMessage('name').length" class="p-error">{{
            fieldErrorMessage('name')
          }}</small>
        </div>

        <div v-if="initialUser" class="col-12 field lg:col-6">
          <label for="email">{{ ts.tForms('email') }}</label>
          <p-input-text
            id="email"
            v-model="initialUser.email"
            class="w-full"
            type="text"
            disabled
          />
          <small v-if="fieldErrorMessage('email').length" class="p-error">{{
            fieldErrorMessage('email')
          }}</small>
        </div>

        <div v-if="initialUser" class="col-12 field lg:col-4">
          <label for="mobile">{{ ts.tForms('mobile') }}</label>
          <p-input-text id="mobile" v-model="initialUser.mobilephone" class="w-full" type="text" />
          <small v-if="fieldErrorMessage('mobilephone').length" class="p-error">{{
            fieldErrorMessage('mobilephone')
          }}</small>
        </div>
        <div v-if="initialUser" class="col-12 field lg:col-4">
          <label for="address">{{ ts.tForms('address') }}</label>
          <p-input-text id="address" v-model="initialUser.address" class="w-full" type="text" />
          <small v-if="fieldErrorMessage('address').length" class="p-error">{{
            fieldErrorMessage('address')
          }}</small>
        </div>

        <div v-if="initialUser" class="col-12 field lg:col-4">
          <label for="region">{{ ts.tForms('region') }}</label>
          <p-input-text id="region" v-model="initialUser.country" class="w-full" type="text" />
          <small v-if="fieldErrorMessage('country').length" class="p-error">{{
            fieldErrorMessage('country')
          }}</small>
        </div>

        <div v-if="initialUser" class="col-12 field">
          <label for="position">{{ ts.tForms('position') }}</label>
          <p-input-text id="position" v-model="initialUser.position" class="w-full" type="text" />
          <small v-if="fieldErrorMessage('position').length" class="p-error">{{
            fieldErrorMessage('position')
          }}</small>
        </div>
        <div v-if="initialUser" class="col-12 field">
          <label for="avatar">{{ ts.tForms('avatar') }}</label>
          <ThumbnailUploader
            id="avatar"
            :is-avatar="initialUser.thumbnail == null"
            :username="initialUser.name"
            :thumbnail="initialUser.thumbnail!"
            :class="{ 'p-error block': hasError('thumbnail_id') }"
            @uploaded="(value) => (initialUser!.thumbnail = value)"
          />
        </div>
        <div
          v-if="
            hasPermission(FeaturePermission.ASSIGN_ROLES) &&
            hasUserPermission(UserPermission.VIEWANY_ROLE) &&
            initialUser
          "
          class="col-12 field"
        >
          <RoleSelect v-model="initialUser.roles[0]" />
        </div>

        <div v-if="initialUser" class="col-12 field">
          <label>{{ ts.tForms('languages') }}</label>
          <LocaleSelect
            v-model="initialUser.locale"
            hide-label
            @fetch-finished="dirtyStateService.changeInitialData(initialUser)"
          />
        </div>

        <div
          v-if="hasUserPermission(UserPermission.VIEWANY_CLIENT) && initialUser"
          class="col-12 field"
        >
          <ClientSelect v-model="initialUser.clients" multiselect />
        </div>

        <div
          v-if="hasUserPermission(UserPermission.VIEWANY_CLIENT) && initialUser"
          class="col-12 field"
        >
          <ClientSelect v-model="initialUser.workspace" />
        </div>

        <div class="col field flex flex-row justify-content-end mt-3">
          <p-button
            :disabled="saving || !dirtyStateService.isTouched()"
            :label="ts.tGlobal('save')"
            :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
            icon-pos="right"
            @click="updateUser"
          />
        </div>
      </div>
    </div>
    <pConfirmDialog group="dirty-dialog" />
  </section>
</template>
