<script setup lang="ts">
import ProductMetaEdit from 'supplier/modules/products/components/product-meta-edit.vue';
import ProductMetaLinkProducts from 'supplier/modules/products/components/product-meta-link-products.vue';
import ProductTasks from 'supplier/modules/products/components/product-tasks.vue';
import ProductPreview from 'supplier/modules/products/components/product-preview.vue';
import useStorage from 'utils/storage';

import useDirtyState from 'composables/dirty-state';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';

import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { LocaleDetails } from 'supplier/modules/locales/locales.types';
import { onMounted, onBeforeMount, ref, watch, computed } from 'vue';
import Activites from 'supplier/modules/activities/views/activities.vue';
import { Product } from 'platform-unit2-api/products';
import { LanguageMenu } from '../ts/products.types';
import GenerateProductData from 'supplier/modules/ai-enhancements/components/product-details/generate-product-data.modal.vue';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { onUnmounted } from 'vue';
import {
  DataTableFilterService,
  FilterPageKey,
} from '@/general/services/data-table-filter-service/data-table-filter.service';
import { formatDate } from '@/general/utils/format-date';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();
const dataTableFilterService = new DataTableFilterService(FilterPageKey.PRODUCT_OVERVIEW);

/** Consts */
const store = useStore();
const router = useRouter();
const route = useRoute();
const storage = useStorage();

const currentProductVariants = computed<Product[] | undefined>(
  () => store.getters['products/currentProductVariants'],
);

const showPreviousProduct = ref(false);
const showNextProduct = ref(false);
const locales = ref<LocaleDetails[]>([]);
const languages = ref<LanguageMenu[]>([]);

const metaEditActive = ref(false);
const metaEditVisible = (value: boolean) => {
  metaEditActive.value = value;
};

const metaLinkProductsActive = ref(false);
const metaLinkProductsVisible = (value: boolean) => {
  metaLinkProductsActive.value = value;
};

const tasksActive = ref(false);
const tasksVisible = (value: boolean) => {
  tasksActive.value = value;
};

const { dirtyStateDialog } = useDirtyState();

const currentProductId = computed(() => Number(route.params.id));

const actions = ref([
  {
    label: ts.tGlobal('edit'),
    icon: 'mdi mdi-pencil-outline',
    command: () => metaEditVisible(true),
  },
  {
    separator: true,
  },
  {
    label: ts.tModule('product_details.link_products.menu'),
    icon: 'mdi mdi-link',
    command: () => metaLinkProductsVisible(true),
  },
  {
    label: ts.tGlobal('delete'),
    class: 'delete',
    icon: 'mdi mdi-delete-outline',
    command: () => {
      confirmService.confirmDelete({
        callback: () => deleteProduct(),
        group: 'product-delete-modal',
        message:
          currentProduct.value?.module_id == null &&
          currentProductVariants.value != null &&
          currentProductVariants.value?.length > 1
            ? ts.tModule('deleteProduct.withVariants')
            : ts.deleteConfirm(),
      });
    },
  },
]);

const getNextProduct = async () => {
  showNextProduct.value = false;

  //here clean the previous next product from the store
  store.dispatch('products/SET_NEXT_PRODUCT', null);
  const products = await store.getters['products/products'];
  //here we find the current product's index in the list of products
  const index = products.data.findIndex((item: Product) => item.id === currentProductId.value);

  //if we are at the end of our pagination get the first element of the next page
  if (products.data.length === index + 1 && products.meta.current_page < products.meta.last_page) {
    //get all the products of the next page
    const newProducts = (
      await store.dispatch('products/GET_PRODUCTS_NO_COMMIT', {
        pagination: {
          page: storage.getStorageItem('overviewPagination').page + 1,
          limit: storage.getStorageItem('overviewPagination').limit,
          query: storage.getStorageItem('overviewPagination').query,
          sortBy: storage.getStorageItem('overviewPagination').sortBy,
        },
        filters: dataTableFilterService.getFiltersForWorkspace(
          store.getters['users/currentUser'].workspace?.name,
        ),
      })
    ).data;

    //if we have products in the next page set the next product
    //else we set the next product to null
    await store.dispatch('products/SET_NEXT_PRODUCT', newProducts[0]);
  } else {
    //here we set the next product to the next product in list list
    store.dispatch('products/SET_NEXT_PRODUCT', products.data[index + 1]);
  }
};

const openNextProduct = () => {
  dirtyStateDialog(
    () => {
      return;
    },
    async () => {
      //find the current product's index in the list of products
      const index = store.getters['products/products'].data.findIndex(
        (item: Product) => item.id === currentProductId.value,
      );

      if (index === storage.getStorageItem('overviewPagination').limit - 1) {
        //here we update the pagination
        const pagination = storage.getStorageItem('overviewPagination');
        pagination.page = pagination.page + 1;
        storage.setStorageItem('overviewPagination', pagination);

        await store.dispatch('products/GET_PRODUCTS', {
          pagination: pagination,
          filters: dataTableFilterService.getFiltersForWorkspace(
            store.getters['users/currentUser'].workspace?.name,
          ),
        });
      }

      store.dispatch('products/SET_CURRENT_PRODUCT', store.getters['products/nextProduct']);
      storage.setStorageItem('currentProduct', store.getters['products/nextProduct']);

      router.push({
        name: 'product-details',
        params: {
          id: store.getters['products/nextProduct']!.id.toString(),
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    },
  );
};

const addLanguage = (locale: LocaleDetails) => {
  let selectedLocales: LocaleDetails[] = store.getters['products/selectedLocales'];
  selectedLocales.length === 1 && selectedLocales[0].id === locale.id //if the user wants to deselect the only selected language
    ? toastService.displaySuccessToast(
        ts.tModule('product_details.product_action_group.removing_not_allowed'),
      )
    : selectedLocales.find((existedLocal) => existedLocal.id === locale.id) === undefined
    ? selectedLocales.push(locale)
    : (selectedLocales = selectedLocales.filter((existedLocale) => existedLocale.id !== locale.id));
  store.dispatch('products/SET_SELECTED_LOCALES', selectedLocales);
};

const getPreviousProduct = async () => {
  showPreviousProduct.value = false;
  store.dispatch('products/SET_PREVIOUS_PRODUCT', null);
  const products = store.getters['products/products'];

  //find the current product's index in the list of products
  const index = products.data.findIndex((item: Product) => item.id === currentProductId.value);

  //if we are at the beginning of our pagination we will find the last
  //element of our previous page
  if (index === 0 && products.meta.current_page > 1) {
    //get the elements of our previous page
    const newProducts = (
      await store.dispatch('products/GET_PRODUCTS_NO_COMMIT', {
        pagination: {
          page: storage.getStorageItem('overviewPagination').page - 1,
          limit: storage.getStorageItem('overviewPagination').limit,
          query: storage.getStorageItem('overviewPagination').query,
          sortBy: storage.getStorageItem('overviewPagination').sortBy,
        },
        filters: dataTableFilterService.getFiltersForWorkspace(
          store.getters['users/currentUser'].workspace?.name,
        ),
      })
    ).data;

    // set the product the the last element of the previous page
    store.dispatch('products/SET_PREVIOUS_PRODUCT', newProducts[newProducts.length - 1]);
  } else {
    //set the previous product to the previous element of the list
    store.dispatch('products/SET_PREVIOUS_PRODUCT', products?.data[index - 1]);
  }
};

const openPrevProduct = async () => {
  dirtyStateDialog(
    () => {
      return;
    },
    async () => {
      //find the current product's index in the list of products
      const index = store.getters['products/products'].data.findIndex(
        (item: Product) => item.id === currentProductId.value,
      );

      if (index === 0) {
        //here we update the pagination
        const pagination = storage.getStorageItem('overviewPagination');
        pagination.page = pagination.page - 1;
        storage.setStorageItem('overviewPagination', pagination);

        await store.dispatch('products/GET_PRODUCTS', {
          pagination: pagination,
          filters: dataTableFilterService.getFiltersForWorkspace(
            store.getters['users/currentUser'].workspace?.name,
          ),
        });
      }

      //set the current product to the previous product
      store.dispatch('products/SET_CURRENT_PRODUCT', store.getters['products/previousProduct']);
      storage.setStorageItem('currentProduct', store.getters['products/previousProduct']);

      await router.push({
        name: 'product-details',
        params: {
          id: store.getters['products/previousProduct']!.id.toString(),
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    },
  );
};

const deleteProduct = async () => {
  try {
    await store.dispatch('products/DELETE_PRODUCT', route.params.id);
    router.push({ name: 'products' } as RouteLocationRaw);
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (err: any) {
    toastService.displayErrorToast(ts.deleteFailed());
  }
};

watch(
  () => store.getters['products/currentProduct'],
  async () => {
    if (store.getters['products/currentProduct']) {
      await getNextProduct();
      await getPreviousProduct();
    }
  },
  {
    deep: true,
  },
);
onMounted(async () => {
  try {
    if (store.getters['products/overviewPagination'] != null) {
      storage.setStorageItem('overviewPagination', store.getters['products/overviewPagination']);
    }

    if (store.getters['products/products'] == null) {
      await store
        .dispatch('products/GET_PRODUCTS', {
          pagination: store.getters['products/overviewPagination'],
          filters: dataTableFilterService.getFiltersForWorkspace(
            store.getters['users/currentUser'].workspace?.name,
          ),
        })
        .then(async () => {
          await getNextProduct();
          await getPreviousProduct();
        });
    }
  } catch {
    //silently fail, this is because the user
    //has refreshed the page and the store is empty
  }
});

onUnmounted(() => {
  store.dispatch('products/SET_PREVIOUS_PRODUCT', null);
  store.dispatch('products/SET_NEXT_PRODUCT', null);
});

onBeforeMount(async () => {
  locales.value = (await store.dispatch('locales/SEARCH_LOCALES', { query: '' })).data;
  locales.value.forEach((locale) =>
    languages.value.push({
      label: locale.language ?? '',
      icon: 'fi fi-' + locale.format + ' fis',
      value: locale,
      command: (e) => {
        addLanguage(e.item.value);
      },
    }),
  );
});

const currentProduct = computed<Product | undefined>(
  () => store.getters['products/currentProduct'],
);
const nextProduct = computed<Product | undefined>(() => store.getters['products/nextProduct']);
const previousProduct = computed<Product | undefined>(
  () => store.getters['products/previousProduct'],
);

/** Activity sidebar */
const activitySidebarActive = ref(false);
const activitySidebarVisible = (value: boolean) => {
  activitySidebarActive.value = value;
};
</script>
<template>
  <div>
    <ProductMetaEdit
      v-if="metaEditActive"
      :current-product="currentProduct"
      :is-active="metaEditActive"
      @hide="metaEditVisible(false)"
    ></ProductMetaEdit>

    <ProductMetaLinkProducts
      v-if="metaLinkProductsActive"
      :current-product="currentProduct"
      :is-active="metaLinkProductsActive"
      :excluded-products="[]"
      @hide="metaLinkProductsVisible(false)"
    ></ProductMetaLinkProducts>

    <ProductTasks
      v-if="tasksActive"
      :is-active="tasksActive"
      @hide="tasksVisible(false)"
    ></ProductTasks>

    <!-- Activity sidebar -->
    <Activites
      v-if="activitySidebarActive"
      :is-active="activitySidebarActive"
      module-name="products"
      :module-id="currentProduct?.id"
      @hide="activitySidebarVisible(false)"
    >
    </Activites>

    <p-confirm-dialog group="product-delete-modal"></p-confirm-dialog>
    <div class="flex flex-column justify-content-between">
      <div class="align-items-center flex">
        <FeatureFlag :flag="featureFlagEnum.AI_ENHANCEMENTS">
          <GenerateProductData />
        </FeatureFlag>

        <pButton
          class="mr-2"
          plain
          outlined
          icon="mdi mdi-calendar-check"
          :label="ts.tModule('tasks.title')"
          @click="tasksVisible(true)"
        />
        <div>
          <pButton
            plain
            outlined
            class="mr-2"
            icon="mdi mdi-clock-outline"
            :label="ts.tModule('product_details.product_action_group.activity')"
            @click="activitySidebarVisible(true)"
          />
        </div>
        <pSplitButton
          :label="ts.tGlobal('actions')"
          :model="actions"
          class="mr-2 p-button-plain"
          outlined
        />
        <div class="border-700 border-left-1 h-full inline-flex ml-2 mr-3 w-1px"></div>
        <span class="p-buttonset">
          <pButton
            plain
            outlined
            icon="mdi mdi-chevron-left"
            :disabled="!previousProduct ? true : false"
            @mouseover="showPreviousProduct = true"
            @mouseleave="showPreviousProduct = false"
            @click="openPrevProduct"
          />
          <div v-if="showPreviousProduct" class="absolute right-0">
            <ProductPreview :product="previousProduct"></ProductPreview>
          </div>
          <pButton
            plain
            outlined
            icon="mdi mdi-chevron-right"
            :disabled="!nextProduct ? true : false"
            @mouseover="showNextProduct = true"
            @mouseleave="showNextProduct = false"
            @click="openNextProduct"
          />
          <div v-if="showNextProduct" class="absolute right-0">
            <ProductPreview :product="nextProduct"></ProductPreview>
          </div>
        </span>
      </div>
      <p class="mt-4 text-right">
        {{ ts.tGlobal('updated_at') }}:
        {{ currentProduct ? formatDate(new Date(currentProduct.updated_at)) : '' }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-splitbutton:deep(.p-splitbutton-defaultbutton),
.p-splitbutton:deep(.p-splitbutton-menubutton) {
  border-color: var(--gray-600);
  color: var(--gray-600);
  cursor: default;

  &:enabled:hover {
    background: white;
    color: var(--gray-600);
  }

  &:enabled:active {
    background: white;
    color: var(--gray-600);
  }
}

.p-splitbutton:deep(.p-splitbutton-menubutton) {
  cursor: pointer;
  &:enabled:hover {
    background: var(--gray-50);
  }
  &:enabled:active {
    background: var(--gray-100);
  }
}
</style>
