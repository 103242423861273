<script setup lang="ts">
import LazyLoader from '@/general/ui/components/lazyLoader.vue';
import ActivityService from 'retailer/modules/activities/activity.service';
import ActivitiesSkeleton from '@/general/ui/components/skeletons/customs/activities-skeleton.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Activity } from 'platform-unit2-api/activities';

/** Define the interface for the props */
interface ActivityProps {
  isActive: boolean;
  moduleName: string;
  moduleId?: number;
}
/** Props */
withDefaults(defineProps<ActivityProps>(), {
  isActive: false,
});

/** Emits */
defineEmits<{
  (e: 'hide'): void;
}>();

/** constants */
const ts = new TranslationService('retailer', 'activities');
const activityService = new ActivityService();

/** Generate the correct component to show the activity details */
const generateActivityBox = (activity: Activity[]) => {
  return activityService.generateActivityBox(activity);
};
</script>
<template>
  <pSidebar
    :visible="isActive"
    class="p-sidebar-md"
    :dismissable="false"
    :modal="false"
    position="right"
    :header="ts.titlePlural"
    @update:visible="$emit('hide')"
  >
    <!-- Activity list -->
    <LazyLoader
      v-if="moduleId"
      :endpoint="`${moduleName}/GET_ACTIVITIES`"
      :params="{ productId: moduleId }"
      :style="{ height: 'calc(100vh - 75px)' }"
      class="mt-5"
    >
      <template #layout="slotProps">
        <div v-if="slotProps.data.length">
          <component
            :is="generateActivityBox(activity)"
            v-for="(activity, index) in slotProps.data"
            :key="index"
            :activity="activity"
          />
        </div>
      </template>

      <!-- Loading -->
      <template #loading>
        <ActivitiesSkeleton />
      </template>
    </LazyLoader>
  </pSidebar>
</template>
<style lang="scss"></style>
