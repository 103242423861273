<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import moment from 'moment';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import { ToastService } from '@/general/services/toasts/toast.service';
import { useRouter, RouteLocationRaw, useRoute } from 'vue-router';
import { Upload as UploadType } from 'platform-unit2-api/uploads';
import MediaPicker from '../../media/components/media-picker.vue';
import { Product } from 'platform-unit2-api/products';

/** Services */
const ts = new TranslationService('supplier', 'products');

/**Consts */
const store = useStore();
const productTimeStatus = ref<'online' | 'offline' | 'toBeOnline' | 'toBeOffline'>();
const mediaPicker = ref(false);
const toastService = ToastService.getInstance();
const router = useRouter();
const route = useRoute();

const loading = ref(false);

const currentProduct = computed<Product | undefined>(
  () => store.getters['products/currentProduct'],
);

const variantName = computed(() => {
  if (!currentProduct.value || !currentProduct.value.variant_name) {
    return '';
  }

  return ` - ${currentProduct.value.variant_name}`;
});

const productTitle = computed(() => {
  if (!currentProduct.value) {
    return '';
  }

  return currentProduct.value.display_name + variantName.value;
});

const productGtin = computed(() => {
  if (!currentProduct.value) {
    return '';
  }

  return currentProduct.value.gtin !== '' ? currentProduct.value.gtin : undefined;
});

const productSubtitle = computed(() => {
  let result = '';
  if (!currentProduct.value) {
    return '';
  }

  const category =
    currentProduct.value.category && currentProduct.value.category !== undefined
      ? currentProduct.value.category.name
      : undefined;

  const brand =
    currentProduct.value.brand && currentProduct.value.brand !== undefined
      ? currentProduct.value.brand.name
      : undefined;

  if (category != null) {
    result += category;
    if (brand != null) {
      result += ` · `;
    }
  }

  if (brand != null) {
    result += brand;
  }

  return result;
});

const setTimeStatus = (): void => {
  if (!currentProduct.value) {
    return;
  }

  const introduced = moment(currentProduct.value.introduced_at);
  const delisted = moment(currentProduct.value.delisted_at);
  const today = moment(new Date());

  if (currentProduct.value.introduced_at && currentProduct.value.delisted_at) {
    productTimeStatus.value =
      today > introduced && today < delisted
        ? 'online'
        : today > delisted
        ? 'offline'
        : 'toBeOnline';
  } else if (currentProduct.value.introduced_at) {
    productTimeStatus.value = today > introduced ? 'online' : 'toBeOnline';
  } else if (currentProduct.value.delisted_at) {
    productTimeStatus.value = today > delisted ? 'offline' : 'toBeOffline';
  } else {
    productTimeStatus.value = undefined;
  }
};

const setGreenStatus = computed(() => {
  if (!currentProduct.value) {
    return '';
  }

  return (
    ts.tModule('product_details.product_meta.online') +
    ' ' +
    (productTimeStatus.value === 'toBeOnline'
      ? moment(new Date()).to(moment(currentProduct.value.introduced_at))
      : '')
  );
});

const setRedStatus = computed(() => {
  if (!currentProduct.value) {
    return '';
  }

  return (
    ts.tModule('product_details.product_meta.offline') +
    ' ' +
    (productTimeStatus.value === 'online' || productTimeStatus.value === 'toBeOffline'
      ? moment(new Date()).to(moment(currentProduct.value.delisted_at))
      : '')
  );
});

const showMediaPicker = (): void => {
  mediaPicker.value = true;
};

const hideMediaPicker = (): void => {
  mediaPicker.value = false;
};

const attachUploadData = async (event: any): Promise<void> => {
  loading.value = true;
  try {
    await store.dispatch('products/ATTACH_MANY', {
      productIds: [route.params.id],
      uploadIds: event.map((file: UploadType) => file.id),
    });
    toastService.displaySuccessToast(ts.tModule('product_assets.success.uploaded_successfully'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('product_assets.warnings.uploading_failed'));
  } finally {
    loading.value = false;
    router.push({
      name: 'product-assets',
    } as RouteLocationRaw);
  }
};

watchEffect(() => {
  currentProduct.value && setTimeStatus();
});

onMounted(() => {
  setTimeStatus();
});
</script>

<template>
  <div v-if="!currentProduct || loading" class="align-items-center flex flex-row">
    <p-skeleton width="4rem" height="4rem" class="mr-3" />
    <div>
      <p-skeleton width="32rem" height="1rem" class="mb-2" />
      <p-skeleton width="24rem" height="0.75rem" class="mb-3" />
      <div class="align-items-center flex flex-row">
        <p-skeleton width="12rem" height="0.5rem" class="mr-3" />
        <p-skeleton shape="circle" size="0.5rem" class="mr-3" />
      </div>
    </div>
  </div>

  <div v-else class="align-items-center flex flex-row">
    <upload
      class="border-round mr-3 w-6rem"
      preview
      :upload="currentProduct.thumbnail"
      :upload-thumbnail="true"
      @upload="showMediaPicker"
    />
    <div class="product-meta">
      <div class="align-items-center flex flex-row mb-1">
        <h4 class="font-bold mr-3">{{ productGtin }}</h4>

        <p-tag
          v-if="productTimeStatus === 'online' || productTimeStatus === 'toBeOnline'"
          class="mr-2 online"
          icon="mdi mdi-circle"
          :value="setGreenStatus"
          :title="setGreenStatus"
          rounded
        />

        <p-tag
          v-if="
            productTimeStatus === 'offline' ||
            (productTimeStatus === 'online' && currentProduct.delisted_at) ||
            productTimeStatus === 'toBeOffline'
          "
          class="mr-2"
          :class="
            moment(currentProduct.delisted_at).isAfter(moment(Date.now()).format('YYYY-MM-DD'))
              ? 'offline-in'
              : 'offline'
          "
          icon="mdi mdi-circle"
          :value="setRedStatus"
          :title="setRedStatus"
          rounded
        />
      </div>

      <p>{{ productTitle }}</p>
      <p>{{ productSubtitle }}</p>
      <ProgressBar :progress="currentProduct.completeness ?? 0" width="w-12rem" />
    </div>

    <MediaPicker
      v-model:visible="mediaPicker"
      :multiple="true"
      :col-size="2"
      @choose-multiple-images="attachUploadData($event)"
      @hide="hideMediaPicker"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep(.p-progressbar) {
  background-color: #334a66;
}
.p-tag.online {
  background-color: #f8fcfa;
  border: 0.5px solid #b0e8c6;
  color: #28864d;
}

.p-tag.offline-in {
  background-color: #44381d;
  color: #f6c85a;
}

.p-tag.offline {
  background-color: #441e1d;
  color: #f35c56;
}
</style>
