<script setup lang="ts">
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product, UpdateProductRequest } from 'platform-unit2-api/products';
import { DatamodelCompact } from 'platform-unit2-api/datamodels';
import { ModuleCompact } from 'platform-unit2-api/modules';
import { CategoryCompact } from 'platform-unit2-api/categories';
import { BrandCompact } from 'platform-unit2-api/brands';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import BrandSelect from '@/general/ui/components/selects/brand-select.vue';
import { FromValidationService } from '@/general/services/form-validation/form-validation.service';

/**Props */

interface Props {
  currentProduct?: Product;
  isActive: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const formValidationService = new FromValidationService();

//** Constants */
const store = useStore();
const loading = ref(false);
const saving = ref(false);
const selectedDatamodel = ref<DatamodelCompact>();
const gtin = ref<string>('');
const productName = ref<string>('');
const selectedModule = ref<ModuleCompact>();
const selectedBrand = ref<BrandCompact>();
const selectedCategory = ref<CategoryCompact>();
const introducedAt = ref<Date>();
const delistedAt = ref<Date>();

const handleSubmit = async () => {
  saving.value = true;
  try {
    const updateProduct: UpdateProductRequest = {
      id: store.getters['products/currentProduct']?.id,
      display_name: productName.value,
      gtin: gtin.value,
      brand_id: selectedBrand.value ? selectedBrand.value.id : null,
      category_id: selectedCategory.value ? selectedCategory.value.id : null,
      module_id: selectedModule.value ? selectedModule.value.id : null,
      introduced_at: introducedAt.value ? introducedAt.value.toISOString() : null,
      delisted_at: delistedAt.value ? delistedAt.value.toUTCString() : null,
      datamodel_id: selectedDatamodel.value?.id ?? null,
    };

    await store.dispatch('products/UPDATE_PRODUCT', {
      productId: store.getters['products/currentProduct']?.id,
      data: updateProduct,
    });

    toastService.displaySuccessToast(ts.updateSuccess());
    emit('hide');
  } catch (ex) {
    formValidationService.handleError(ex, () => {
      ts.updateFailed();
    });
  } finally {
    saving.value = false;
  }
};

const setValues = () => {
  if (props.currentProduct) {
    loading.value = true;
    productName.value = props.currentProduct?.display_name ?? '';
    gtin.value = props.currentProduct.gtin ?? '';
    selectedBrand.value = props.currentProduct.brand;
    selectedCategory.value = props.currentProduct.category;
    selectedModule.value = props.currentProduct.module ?? undefined;
    selectedDatamodel.value = props.currentProduct.datamodel ?? undefined;
    introducedAt.value = props.currentProduct.introduced_at
      ? new Date(props.currentProduct.introduced_at)
      : undefined;
    delistedAt.value = props.currentProduct.delisted_at
      ? new Date(props.currentProduct.delisted_at)
      : undefined;

    loading.value = false;
  }
};

watch(
  () => props.currentProduct,
  () => {
    setValues();
  },
);

onMounted(() => {
  setValues();
});
</script>

<template>
  <pSidebar
    :visible="isActive"
    class="p-sidebar-md"
    :dismissable="false"
    position="right"
    :header="ts.tModule('product_details.product_meta_edit.update_product_data')"
    @update:visible="$emit('hide')"
  >
    <div class="h-full">
      <sidebar-skeleton v-if="loading || !currentProduct" />
      <div v-else class="flex flex-column h-full">
        <div class="field mb-3">
          <label for="product-name">{{ ts.tModule('product_name') }}</label>
          <p-input-text id="product-name" v-model="productName" class="w-full" type="text" />
        </div>

        <div class="field mb-3">
          <label for="gtin">{{ ts.tGlobal('gtin') }}</label>
          <p-input-text id="gtin" v-model="gtin" class="w-full" type="text" />
          <small
            :class="{ 'p-error block': formValidationService.hasError('gtin') }"
            class="hidden"
            >{{ formValidationService.fieldErrorMessage('gtin').toString() }}</small
          >
        </div>

        <div v-if="currentProduct.variant_name" class="field mb-3">
          <ModuleSelect id="module" v-model="selectedModule" />
        </div>

        <div class="field mb-3">
          <BrandSelect v-model="selectedBrand" />
        </div>
        <!-- just show category select in Master data -->
        <div v-if="!currentProduct.module_id" class="field mb-3">
          <CategorySelect v-model="selectedCategory" />
        </div>
        <div v-if="selectedModule?.template_id != null" class="field mb-3">
          <DatamodelSelect v-model="selectedDatamodel" :template-id="selectedModule?.template_id" />
        </div>

        <div class="field mb-3">
          <label for="introduced_at">
            {{
              ts.tModule('product_details.product_meta_edit.introduced_at') +
              ' (' +
              ts.tGlobal('optional') +
              ')'
            }}
          </label>
          <p-calendar
            v-model="introducedAt"
            show-time
            show-icon
            show-button-bar
            date-format="dd/mm/yy"
            class="w-full"
          />
        </div>

        <div class="field mb-3">
          <label for="delisted_at">
            {{
              ts.tModule('product_details.product_meta_edit.delisted_at') +
              ' (' +
              ts.tGlobal('optional') +
              ')'
            }}
          </label>
          <p-calendar
            v-model="delistedAt"
            :min-date="introducedAt"
            show-time
            show-icon
            show-button-bar
            date-format="dd/mm/yy"
            class="w-full"
          />
        </div>

        <div
          class="bg-white border-100 border-top-1 bottom-0 flex justify-content-end mt-auto py-3 sticky"
        >
          <p-button text plain :label="ts.tGlobal('cancel')" class="mr-2" @click="$emit('hide')" />
          <p-button
            :disabled="saving"
            :label="ts.tGlobal('save')"
            :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
            icon-pos="right"
            @click="handleSubmit()"
          />
        </div>
      </div>
    </div>
  </pSidebar>
</template>
