<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import ProductSaveModalV2 from 'supplier/modules/products/components/product-save-modalV2.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import {
  productAttributeFieldsServiceKey,
  productAttributeValidationServiceKey,
} from '@/general/services/attribute-fields/service-keys';
import { VariantsRestService } from 'platform-unit2-api/variants';
import { ProductStatusesRestService, ProductStatus } from 'platform-unit2-api/product-statuses';
import { Product, ProductsRestService, UpdateProductRequest } from 'platform-unit2-api/products';
import { ActionRestService } from 'platform-unit2-api/actions';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const productAttributeValidationService = inject(productAttributeValidationServiceKey)!;
const confirmService = new ConfirmService();
const actionsApi = new ActionRestService();
const variantsApi = new VariantsRestService();
const productStatusApi = new ProductStatusesRestService();
const productRestApi = new ProductsRestService();

/**Consts */
const store = useStore();
const route = useRoute();
const statuses = ref<(ProductStatus & { command: () => void })[]>([]);
const isSaving = ref(false);
const isDeleting = ref(false);
const isLoadingProductDetails = ref(false);
const productSaveModalActive = ref(false);
const productSaveModalOpen = ref(false);
const visible = ref(false);

const isLoading = computed(() => {
  return isSaving.value || isDeleting.value || isLoadingProductDetails.value;
});

const transitionState = async (id: number) => {
  try {
    if (!productAttributeFieldsService.value.currentProduct) {
      return;
    }

    isSaving.value = true;

    const productUpdateRequest: UpdateProductRequest = {
      id: productAttributeFieldsService.value.currentProduct.id,
      product_status_id: id,
      display_name: productAttributeFieldsService.value.currentProduct.display_name ?? '',
      gtin: productAttributeFieldsService.value.currentProduct.gtin ?? '',
      introduced_at: productAttributeFieldsService.value.currentProduct.introduced_at ?? null,
      delisted_at: productAttributeFieldsService.value.currentProduct.delisted_at ?? null,
    };

    productAttributeFieldsService.value.currentProduct = (await productRestApi.update(
      productAttributeFieldsService.value.currentProduct?.id,
      productUpdateRequest,
    )) as Product;

    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (ex) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    isSaving.value = false;
  }
};

const loadAsyncData = async (): Promise<void> => {
  try {
    const fetchedStatusses = (await productStatusApi.getAll()).data;

    statuses.value = fetchedStatusses.map((status) => {
      return {
        ...status,
        command: () => {
          transitionState(status?.id);
        },
      };
    });
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    visible.value = productAttributeFieldsService.value.currentProduct?.public ?? false;
  }
};

const productSaveModalVisible = (value: boolean) => {
  productSaveModalActive.value = value;
};

const saveProduct = async (overrides: { attributes: number[]; variants: number[] }) => {
  isSaving.value = true;

  productAttributeFieldsService.value
    .saveAttributeFields(overrides)
    .then(async () => {
      store.dispatch('products/SET_LOADING_COMPLETENESS', true);

      toastService.displaySuccessToast(
        ts.updateSuccess(productAttributeFieldsService.value.currentProduct?.display_name),
      );
    })
    .catch(() => {
      toastService.displayErrorToast(
        ts.updateFailed(productAttributeFieldsService.value.currentProduct?.display_name),
      );
    })
    .finally(() => {
      isSaving.value = false;
      productSaveModalOpen.value = false;
    });
};

const resetProductHandler = (event: PointerEvent) => {
  confirmService.confirmDelete({
    event: event,
    callback: () => productAttributeFieldsService.value.discardChanges(),
    group: 'revert-changes',
    message: ts.tModule('product_details.product_status_bar.reset_product'),
  });
};

const saveConfirm = async () => {
  if (productAttributeFieldsService.value.currentProduct?.variant_name == null) {
    //If it's Master Data, check for the variants
    try {
      if (!productAttributeFieldsService.value.currentProduct?.variant_uuid) {
        return;
      }

      const variants = await variantsApi.getProductVariants(
        productAttributeFieldsService.value.currentProduct?.variant_uuid,
      );

      variants?.length > 1 //other variants except from the current product
        ? (productSaveModalActive.value = true)
        : saveProduct({ variants: [], attributes: [] });
    } catch (ex) {
      saveProduct({ variants: [], attributes: [] });
    }
  } else {
    saveProduct({ variants: [], attributes: [] });
  }
};

const shareWithRetailer = async () => {
  visible.value = !visible.value;
  try {
    visible.value
      ? await actionsApi.shareWithRetailer({ product_ids: [parseInt(route.params?.id.toString())] })
      : await actionsApi.unshareWithRetailer({
          product_ids: [parseInt(route.params?.id.toString())],
        });
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  }
};

watch(
  () => productAttributeFieldsService.value.currentProduct?.public,
  (newValue, _) => {
    if (newValue != null) {
      loadAsyncData();
    }
  },
);
</script>
<template>
  <div
    class="bg-white flex justify-content-between pup-p-3"
    :style="{
      boxShadow: '0 -10px 10px -10px rgba(0, 0, 0, 0.12)',
      zIndex: 10,
    }"
  >
    <ProductSaveModalV2
      v-if="productAttributeFieldsService.currentProduct && productSaveModalActive"
      :is-active="productSaveModalActive"
      :product-id="productAttributeFieldsService.currentProduct?.id"
      @saved="saveProduct"
      @hide="productSaveModalVisible(false)"
    />
    <div class="align-items-center flex">
      <div
        v-for="(status, index) in statuses"
        :key="status?.id"
        class="align-items-center flex pup-ml-1"
      >
        <span
          :style="{
            color:
              productAttributeFieldsService.currentProduct?.status?.id === status?.id
                ? '#ffffff'
                : '#0089d7',
            backgroundColor:
              productAttributeFieldsService.currentProduct?.status?.id === status?.id
                ? '#0089d7'
                : '#E0F0FF',
          }"
          class="align-items-center border-circle flex flex-shrink-0 h-2rem justify-content-center text-lg w-2rem"
          >{{ index + 1 }}</span
        >
        <p class="mx-2">{{ status.label }}</p>
        <p-divider v-if="index < statuses?.length - 1" class="mx-2" />
      </div>
    </div>
    <div class="align-items-center flex">
      <p-confirm-popup group="product-delete" />
      <p-confirm-dialog group="revert-changes" />

      <p
        v-if="
          productAttributeFieldsService.currentProduct &&
          productAttributeFieldsService.hasAttributeFieldChanges
        "
        class="text-gray-400"
      >
        {{ ts.tModule('product_details.product_status_bar.unsaved_changes') }}
      </p>
      <pButton
        v-if="
          productAttributeFieldsService.currentProduct &&
          productAttributeFieldsService.hasAttributeFieldChanges
        "
        label="Discard changes"
        icon="mdi
      mdi-delete-outline"
        :disabled="isLoading || productAttributeFieldsService.loading"
        severity="danger"
        text
        class="pup-mx-3 underline"
        @click="resetProductHandler($event)"
      />

      <pButton
        v-if="productAttributeFieldsService.currentProduct?.variant_name != null"
        rounded
        text
        class="pup-mr-3 text-4xl"
        :icon="visible ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"
        @click="shareWithRetailer()"
      />
      <pButton
        v-if="
          productAttributeFieldsService.currentProduct?.module_id != null &&
          productAttributeFieldsService.originalAttributes.length > 0
        "
        :label="ts.tModule('validations')"
        :loading="isSaving"
        outlined
        plain
        icon="mdi mdi-list-status"
        class="pup-mr-3"
        @click="productAttributeValidationService.toggleValidationPanel()"
      />
      <pButton
        v-if="
          productAttributeFieldsService.currentProduct &&
          productAttributeFieldsService.hasAttributeFieldChanges &&
          !productAttributeFieldsService.isSaveButtonDisabled() &&
          !productAttributeFieldsService.loading
        "
        :label="ts.tGlobal('save')"
        :loading="isSaving"
        severity="success"
        @click="saveConfirm(), productAttributeValidationService.toggleValidationPanel(false)"
      />
      <div
        v-else-if="
          productAttributeFieldsService.hasAttributeFieldChanges &&
          productAttributeFieldsService.isSaveButtonDisabled()
        "
        v-tooltip.top="{
          value: ts.tModule('disabledButtonErrorToolTips'),
          class: 'text-sm',
        }"
      >
        <p-button :label="ts.tGlobal('save')" :loading="isSaving" disabled severity="success" />
      </div>

      <pSplitButton
        v-if="
          productAttributeFieldsService.currentProduct &&
          !productAttributeFieldsService.hasAttributeFieldChanges
        "
        :disabled="isLoading || productAttributeFieldsService.hasAttributeFieldChanges"
        severity="success"
        :label="
          productAttributeFieldsService.currentProduct.status &&
          productAttributeFieldsService.currentProduct.status.label
        "
        :pt="{
          root: {
            style: 'font-size: 1rem',
          },
        }"
        :model="statuses"
      />
    </div>
  </div>
</template>
