<script setup lang="ts">
import { useStore } from 'vuex';
import { inject } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { getLocaleDetails } from '@/general/utils/get-locale-details';

/** Services */
const ts = new TranslationService('supplier', 'products');

/**Props */
interface Props {
  isActive: boolean;
}

withDefaults(defineProps<Props>(), {
  isActive: false,
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/**Consts */
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;
const store = useStore();

const generateFlag = (format?: string): string => {
  if (format == null) {
    return '';
  }

  return 'fi fi-' + format + ' fis mr-1';
};
</script>

<template>
  <pSidebar
    :visible="isActive"
    class="p-sidebar-sm"
    :dismissable="true"
    :modal="false"
    :header="ts.tModule('filters')"
    position="right"
    @update:visible="emit('hide')"
  >
    <div class="align-items-center field flex flex flex-column mb-3">
      <div class="flex justify-content-between mb-3 w-full">
        <label for="missingFields" class="mb-0 mr-3">{{ ts.tModule('show_missing_fields') }}</label>
        <p-input-switch
          id="missingFields"
          v-model="productAttributeFieldService.showOnlyEmptyFields"
          @change="
            store.dispatch('products/SET_MISSING_FIELDS'),
              productAttributeFieldService.setEmptyFields(
                productAttributeFieldService.showOnlyEmptyFields,
              )
          "
        />
      </div>

      <div class="flex justify-content-between w-full">
        <label for="showFieldWithErrors" class="mb-0 mr-3">{{
          ts.tModule('show_fields_with_error')
        }}</label>

        <pInputSwitch
          id="showFieldWithErrors"
          v-model="productAttributeFieldService.showFieldsWithError"
          @change="
            productAttributeFieldService.setShowErrorFields(
              productAttributeFieldService.showFieldsWithError,
            )
          "
        />
      </div>
    </div>
    <div
      v-for="locale of productAttributeFieldService.workspaceLocales.map((l) =>
        getLocaleDetails(l),
      )"
      :key="locale.id"
      class="field-checkbox"
    >
      <p-checkbox
        v-model="productAttributeFieldService.chosenLocaleIds"
        :input-id="locale.id.toString()"
        :value="locale.id"
        :disabled="
          productAttributeFieldService.chosenLocaleIds.length === 1 &&
          productAttributeFieldService.chosenLocaleIds[0] === locale.id
        "
        name="locale"
      />
      <label :for="locale.id.toString()" class="flex">
        <span :class="generateFlag(locale?.format)" class="mr-2" />
        <div>
          <p>
            {{ locale.language }}
          </p>
          <small>({{ locale.region }})</small>
        </div>
      </label>
    </div>
  </pSidebar>
</template>
