<script setup lang="ts">
import { destroyService, useService } from '@/general/composables/useService';
import { DatamodelAttributesService } from '@/general/services/overview-service/modules/datamodel-attributes.service';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';
import { onBeforeRouteLeave } from 'vue-router';

const datamodelService = useService(datamodelAttributeServiceKey, DatamodelAttributesService);

onBeforeRouteLeave((to, __, next) => {
  if (datamodelService.isTouched) {
    datamodelService.dirtyStateUserAttribute.showDirtyDialog(
      () => {
        if (to.fullPath === '/productsup') {
          window.open('https://platform.productsup.com', '_blank');
        } else {
          destroyService(datamodelAttributeServiceKey);

          next();
        }
      },
      undefined,
      next,
      undefined,
      datamodelService.isTouched,
    );
  } else {
    destroyService(datamodelAttributeServiceKey);

    next();
  }
});
</script>
<template>
  <ApplicationHeader>
    <template #breadcrumbs>
      <router-link :to="{ name: 'datamodels' }">
        <pButton
          class="custom-button-styles p-button-outlined p-button-sm"
          icon="mdi mdi-chevron-left"
          :label="datamodelService.ts.titlePlural"
        />
      </router-link>
    </template>
    <template #content>
      <div class="align-items-center mt-3 pb-4">
        <h3
          v-if="!datamodelService.loadingUpdateDatamodelDialog"
          data-testid="breadcrumb-header-title"
        >
          {{ datamodelService.datamodel?.name }}
        </h3>
        <p-skeleton v-else width="30rem" height="28px" />
      </div>
    </template>
  </ApplicationHeader>
</template>
<style lang="scss" scoped>
.custom-button-styles {
  border-color: #dce5ef !important;
  color: black !important;
}
</style>
