<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { openMailClientWithSupportPrefilled } from '@/general/utils/mail-tp-rpx';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('general', 'components');
</script>
<template>
  <pSidebar
    class="p-sidebar-md"
    :dismissable="false"
    :modal="false"
    position="right"
    @update:visible="emit('hide')"
  >
    <template #header>
      <h3>{{ ts.tModule('helpAndSupport.title') }}</h3>
    </template>
    <a
      class="align-items-center cursor-pointer flex flex-row hover:bg-gray-50 mb-3 p-3 text-color w-full"
      href="https://docs.worldofcontent.com/en/"
      target="_blank"
    >
      <img src="@/assets/icons/pup_bulb.svg" class="mr-5" />
      <div class="mr-5">
        <h2 class="mb-2 text-lg">{{ ts.tModule('helpAndSupport.helpCenter.title') }}</h2>
        <p>{{ ts.tModule('helpAndSupport.helpCenter.description') }}</p>
      </div>
      <i class="hover:text-primary mdi mdi-open-in-new mr-5" style="font-size: 1.5rem" />
    </a>
    <a
      href="https://education.productsup.com/#/curricula/932e7847-d745-4d48-83f2-4b686d386452"
      target="_blank"
      class="align-items-center cursor-pointer flex flex-row hover:bg-gray-50 mb-3 p-3 text-color w-full"
    >
      <img src="@/assets/icons/pup_academy.svg" class="mr-5" />
      <div class="mr-5">
        <h2 class="mb-2 text-lg">{{ ts.tModule('helpAndSupport.platformAcademy.title') }}</h2>
        <p>{{ ts.tModule('helpAndSupport.platformAcademy.description') }}</p>
      </div>
      <i class="hover:text-primary mdi mdi-open-in-new mr-5" style="font-size: 1.5rem" />
    </a>
    <div
      class="align-items-center cursor-pointer flex flex-row hover:bg-gray-50 p-3 w-full"
      @click="openMailClientWithSupportPrefilled()"
    >
      <img src="@/assets/icons/pup_support.svg" class="mr-5" />
      <div class="mr-5">
        <h2 class="mb-2 text-lg">{{ ts.tModule('helpAndSupport.contactSupport.title') }}</h2>
        <p>
          {{ ts.tModule('helpAndSupport.contactSupport.description') }}
        </p>
      </div>
      <i class="hover:text-primary mdi mdi-open-in-new mr-5" style="font-size: 1.5rem" />
    </div>
  </pSidebar>
</template>
