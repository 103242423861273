<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import { computed, ref, onMounted } from 'vue';
import { RouteLocationRaw, RouteParamsRaw, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DataTableRowClickEvent } from 'primevue/datatable';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { formatDate } from '@/general/utils/format-date';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'dashboard');

/** Constants */
const store = useStore();
const router = useRouter();
const loading = ref(true);

const dashboardData = computed(() => {
  return store.getters['dashboard/data'];
});

const getData = async () => {
  loading.value = true;
  try {
    await store.dispatch('dashboard/GET_DATA');
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tGlobal('data').toLocaleLowerCase()));
  } finally {
    loading.value = false;
  }
};

const navigateTo = (event: DataTableRowClickEvent) => {
  router.push({
    name: 'product-attributes',
    params: {
      id: event.data.id,
    } as RouteParamsRaw,
  } as RouteLocationRaw);
};

/** Lifecycle Methods */
onMounted(async () => {
  await getData();
});
</script>
<template>
  <p-card>
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('user_dashboard.updated_products.title') }}</h4>
      <pDataTable
        v-if="dashboardData"
        :value="dashboardData.recently_updated"
        removable-sort
        :row-hover="true"
        @row-click="(event: DataTableRowClickEvent) => navigateTo(event)"
      >
        <p-column field="thumbnail">
          <template #body="slotProps">
            <Upload class="w-5rem" :upload="slotProps.data.thumbnail" />
          </template>
        </p-column>
        <p-column field="display_name" :header="ts.tGlobal('name')" :sortable="true" />
        <p-column field="variant_name" :header="ts.tGlobal('variant')" :sortable="true" />
        <p-column field="completeness" :header="ts.tModule('completeness')" :sortable="true">
          <template #body="slotProps">
            <ProgressBar :progress="Math.round(slotProps.data.completeness) ?? 0" width="w-10rem" />
          </template>
        </p-column>
        <p-column field="updated_at" :header="ts.tGlobal('updated_at')" :sortable="true">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.updated_at) }}
          </template>
        </p-column>
        <template #empty>
          <EmptyState
            :translation-service="ts"
            :icon-name="'products'"
            :empty-state-title="ts.tModule('emptyState.products.title')"
            :empty-state-subtitle="ts.tModule('emptyState.products.subtitle')"
            :button-label="ts.tModule('emptyState.products.button')"
            :button-icon="'mdi mdi-plus'"
            @clicked="router.push({ name: 'new-product' })"
          />
        </template>
      </pDataTable>
    </template>
  </p-card>
</template>

<style lang="scss" scoped>
.p-progressbar {
  height: 0.5rem;
}
</style>
