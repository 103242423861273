<script setup lang="ts">
import { Share } from 'platform-unit2-api/shared';
import { inject, onMounted } from 'vue';
import { sharedServiceKey } from '../ts/keys/shared.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import Upload from '@/general/ui/components/upload.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { formatDate } from '@/general/utils/format-date';

const service = inject(sharedServiceKey)!;
const ts = inject(tsKey)!;

onMounted(() => {
  if ((service.value.page ?? 0) > 1) {
    service.value.resetPagination();
  }

  service.value.fetchAll();
});
</script>
<template>
  <pDataTable
    scrollable
    scroll-height="flex"
    :value="service.sharedByMe"
    lazy
    row-hover
    :paginator="service.limit ? (service.total ?? 0) > service.limit : false"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    @page="service.onPageChange($event.page)"
  >
    <p-column :header="ts.tModule('table_header.shared_to')" width="1">
      <template #body="slotProps">
        <Upload :upload="slotProps.data?.owner?.thumbnail" class="w-5rem" />
      </template>
    </p-column>
    <p-column field="share_to">
      <template #body="slotProps">
        {{ slotProps.data.share_to ? slotProps.data.share_to.name : ts.tGlobal('unknown_user') }}
      </template>
    </p-column>
    <p-column field="products_count" :header="ts.tGlobal('products', { params: { count: 2 } })" />
    <p-column field="products_eans" :header="ts.tModule('table_header.eans')">
      <template #body="slotProps: { data: Share }">
        <div
          style="
            max-height: 8rem;
            cursor: copy;
            border: 1px solid #f2f4f5;
            border-radius: 6px;
            padding: 3px;
          "
          class="h-5rem overflow-y-auto"
          @click="service.copyEans(slotProps.data.eans)"
        >
          <p v-for="ean in slotProps.data.eans" :key="ean" class="mb-1">{{ ean }}</p>
        </div>
      </template>
    </p-column>

    <p-column field="status" :header="ts.tGlobal('status')">
      <template #body="slotProps">
        <div v-if="slotProps.data.status === 1">
          <StatusChip :label="ts.tModule('statuses.pending')" severity="info" />
        </div>
        <div v-else-if="slotProps.data.status === 2">
          <StatusChip :label="ts.tModule('statuses.imported')" severity="success" />
        </div>

        <div v-else-if="slotProps.data.status === 3">
          <StatusChip :label="ts.tModule('statuses.declined')" severity="danger" />
        </div>

        <div v-else>
          <StatusChip label="" severity="info" />
        </div>
      </template>
    </p-column>

    <p-column field="created_at" :header="ts.tGlobal('created_at')">
      <template #body="slotProps">
        {{ formatDate(slotProps.data.created_at) }}
      </template>
    </p-column>
    <p-column field="icons" :header="ts.tGlobal('actions')">
      <template #body="slotProps: { data: Share }">
        <pButton
          v-tooltip.top="{
            value: ts.tModule('goToProducts'),
          }"
          icon="mdi mdi-open-in-new"
          text
          plain
          @click="service.goToProducts(slotProps.data.product_ids)"
        />
      </template>
    </p-column>

    <!-- Empty State -->
    <template #empty>
      <EmptyState
        :empty-state-title="ts.tModule('emptyState.sharedByTitle')"
        :empty-state-subtitle="ts.tModule('emptyState.sharedBySubtitle')"
        :translation-service="ts"
        :icon-name="'shared-by'"
        :button-label="ts.tGlobal('productOverview')"
        @clicked="service.goToProducts()"
      />
    </template>
  </pDataTable>
</template>
