<script setup lang="ts">
import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import usePagination from 'composables/usePagination/pagination';
import useSortable from 'composables/sortable';
import { formatDate } from '@/general/utils/format-date';

import { computed, onMounted, ref, watch } from 'vue';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Template, TemplatesRestService } from 'platform-unit2-api/templates';
import { DataTablePageEvent, DataTableRowClickEvent, DataTableSortEvent } from 'primevue/datatable';

import useDebounce from 'utils/debounce';

/* Composables */
const router = useRouter();
const route = useRoute();
const { debounce } = useDebounce();

const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const { sort, onSortChange: $onSortChange } = useSortable();
let searchCallback: any = undefined;

/* Services */
const ts = new TranslationService('admin', 'templates');
const toastService = ToastService.getInstance();
const confirmService = new ConfirmService();
const templatesApi = new TemplatesRestService();

/* Constants */
const loading = ref(false);
const showSidebar = ref(false);
const loadedOnce = ref(false);

const templates = ref({} as ListResponse<Template>);

const total = computed(() => {
  return templates.value && templates.value.meta ? templates.value.meta.total : 0;
});

const templatesData = computed(() => {
  return templates.value ? templates.value.data : [];
});

const loadAsyncData = async (searchQuery?: string): Promise<void> => {
  loading.value = true;
  if (searchQuery != undefined) {
    query.value = searchQuery;
  }

  try {
    templates.value = await templatesApi.getAll({
      query: query.value,
      page: page.value,
      limit: perPage.value,
      sortBy: sort.value,
    } as PaginationObject);
  } catch {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
    loadedOnce.value = true;
  }
};

const search = async (query: string) => {
  if (searchCallback) {
    searchCallback.cancel();
  }

  searchCallback = debounce(async () => {
    try {
      await loadAsyncData(query);
    } catch (err: any) {
      ToastService.getInstance().displayErrorToast(ts.searchFailed(query));
    }
  }, 420);
  searchCallback();
};

const deleteTemplate = async (id: number): Promise<void> => {
  loading.value = true;
  try {
    await templatesApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    loading.value = false;
  }
};

const confirmTemplateDelete = (event: PointerEvent, template: Template): void => {
  confirmService.confirmDelete({
    event: event,
    group: 'templates',
    message: ts.deleteConfirm(template.name),
    callback: () => deleteTemplate(template.id),
  });
};

const showDetails = async (event: DataTableRowClickEvent): Promise<void> => {
  router.push({
    name: 'template-edit',
    params: { id: event.data.id.toString() } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const hideDetails = (): void => {
  router.push({
    name: 'templates',
  } as RouteLocationRaw);
};

const fileExist = (url: string): string => {
  return url.substring(url.lastIndexOf('/') + 1);
};

onMounted(() => {
  if (route.name === 'new-template' || route.name === 'template-edit') {
    showSidebar.value = true;
  }

  loadAsyncData();
});

watch(
  () => route,
  () => {
    if (route.name === 'new-template' || route.name === 'template-edit') {
      showSidebar.value = true;
    }

    if (route.name === 'templates') {
      showSidebar.value = false;
      loadAsyncData();
    }
  },
  {
    deep: true,
  },
);

const onPageChange = (event: DataTablePageEvent): void => {
  $onPageChange(event.page + 1, loadAsyncData);
};

const onSortChange = (event: DataTableSortEvent) => {
  if (event.sortOrder == null) {
    return;
  }

  $onSortChange((event.sortOrder < 0 ? '-' : '') + event.sortField, loadAsyncData);
};
</script>
<template>
  <section class="flex flex-column h-full pt-3 px-4">
    <pIconField icon-position="left" class="my-3">
      <pInputIcon class="pi pi-search" />
      <pInputText
        :placeholder="ts.tGlobal('search')"
        @update:model-value="(value: string) => search(value)"
      />
    </pIconField>

    <div>
      <pDataTable
        scrollable
        scroll-height="flex"
        :value="templatesData"
        removable-sort
        :loading="loading"
        :row-hover="true"
        responsive-layout="scroll"
        :lazy="true"
        :paginator="total > perPage"
        :rows="perPage"
        :total-records="total"
        :first="(page - 1) * perPage"
        @row-click="(event: DataTableRowClickEvent) => showDetails(event)"
        @page="(event: DataTablePageEvent) =>onPageChange(event)"
        @sort="(event: DataTableSortEvent) =>onSortChange(event)"
      >
        <p-column field="name" :header="ts.tGlobal('name')">
          <template #body="slotProps">
            {{ slotProps.data.name }}
            <p-badge
              v-if="slotProps.data.type"
              :value="slotProps.data.type.toUpperCase()"
              class="ml-3"
            />
          </template>
        </p-column>
        <p-column field="updated_at" :header="ts.tGlobal('lastUpdate')">
          <template #body="slotProps">
            {{ slotProps.data.updated_at ? formatDate(slotProps) : ts.tGlobal('notUpdated') }}
          </template>
        </p-column>
        <p-column>
          <template #body="slotProps">
            <div class="flex justify-content-end">
              <a
                v-if="fileExist(slotProps.data.public_url)"
                :href="slotProps.data.public_url"
                target="_blank"
              >
                <p-button
                  v-tooltip.bottom="ts.tGlobal('download')"
                  icon="mdi mdi-download"
                  text
                  rounded
                />
              </a>
              <p-button
                v-tooltip.bottom="ts.deleteButton"
                icon="mdi mdi-delete-outline"
                text
                rounded
                severity="danger"
                @click="confirmTemplateDelete($event, slotProps.data)"
              />
            </div>
          </template>
        </p-column>
        <template #empty>
          <p-message p-message severity="info" :closable="false">
            {{ ts.notFoundWarning }}
          </p-message>
        </template>
      </pDataTable>
    </div>

    <pSidebar
      v-model:visible="showSidebar"
      class="p-0 p-sidebar-lg sidebar-crud w-full"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="loadAsyncData" />
    </pSidebar>
    <p-confirm-popup group="templates" />
  </section>
</template>

<style scoped lang="scss">
.p-sidebar :deep(.p-sidebar-header) {
  padding: 0;
  padding-top: 1rem;
}
</style>
