import { isNegative } from '@/general/utils/isNegative';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { OverviewBase } from './../interfaces/overview-base.interface';
import { TranslationService } from '../../translations/translation.service';
import { useRoute } from 'vue-router';

import { DirtyStateService } from '../../dirty-state/dirty-state.service';
import { FromValidationService } from '../../form-validation/form-validation.service';
import { ToastService } from '../../toasts/toast.service';
import {
  Datamodel,
  DatamodelsRestService,
  DatamodelAttributeConfig,
} from 'platform-unit2-api/datamodels';
import { Attribute } from 'platform-unit2-api/attributes';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { User } from 'platform-unit2-api/users';
import store from '@/core/store';
import { cloneDeep } from 'lodash';
import { OrderableItem } from '@/general/composables/useDataModelOrderable/types';
import { ConfirmService } from '@/general/services/confirm/confirm.service';

export class DatamodelAttributesService extends RefetchService implements OverviewBase {
  private _restService: DatamodelsRestService;
  public datamodel?: Datamodel;

  public dirtyStateUserAttribute: DirtyStateService<Map<number, Attribute>> =
    new DirtyStateService();

  private _startingUserDatamodelName: string | undefined;
  public isLoadingParent = true;
  public deleted = false;

  private _route = useRoute();
  public ts: TranslationService;
  private _confirmService: ConfirmService;

  /**
   * @number Attribute.id
   * @attribute Attribute
   */
  public attributes: Map<number, Attribute> = new Map<number, Attribute>();

  public removeWarning = false;
  public searchKey = '';

  public get attributesCount(): number {
    return this.selectedODMs.reduce(
      (total, datamodel) => total + (datamodel.attributes_count ?? 0),
      0,
    );
  }
  public overrides = new Map<number, DatamodelAttributeConfig>();
  public showOverride = false;

  public attributeToOverride?: Attribute;
  public selectedOverride?: DatamodelAttributeConfig;

  public loadingOdm = false;
  private _loadingOverwrites = false;
  private _loadingDefaultValues = false;
  private _loadingSaveDatamodel = false;
  private _loadingRenameDatamodel = false;

  public get loadingUpdateDatamodelDialog(): boolean {
    if (
      this.loadingOdm ||
      this._loadingOverwrites ||
      this._loadingDefaultValues ||
      this._loadingSaveDatamodel ||
      this._loadingRenameDatamodel ||
      this._isLoadingOverview ||
      this.isLoadingParent
    ) {
      return true;
    }

    return false;
  }

  public odmDatamodels: Datamodel[] = [];

  public duplicationModalVisible = false;

  public get userAttributes(): Attribute[] {
    return Array.from(this.attributes.values());
  }

  public get filteredAttributes(): Attribute[] {
    if (this.searchKey) {
      return this.orderedUserAttributes.filter(
        (attr: Attribute) =>
          attr.key.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          this.overrides.get(attr.id)?.name?.toLowerCase()?.includes(this.searchKey.toLowerCase()),
      );
    }

    return this.userAttributes;
  }

  public get filteredOdmAttributes(): Attribute[] {
    if (this.odmSearchQuery) {
      return this.selectedODMs
        .map((dm) => dm.attributes)
        .flat()
        .filter((attr: Attribute) =>
          attr.key.toLowerCase().includes(this.odmSearchQuery.toLowerCase()),
        );
    }

    return [];
  }

  private _formValidationService: FromValidationService;
  private _isLoadingOverview = true;
  private _isInitialized = false;
  private _toastService: ToastService;

  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  public get isLoadingCrudComponent(): boolean {
    return false;
  }

  public get isLoadingOverView(): boolean {
    return this._isLoadingOverview;
  }

  public get isSavingAttributes() {
    return this._loadingSaveDatamodel;
  }

  public get isTouched(): boolean {
    if (!this._startingUserDatamodelName) {
      return false;
    }

    return (
      this.dirtyStateUserAttribute.isTouched() ||
      this.datamodel?.name !== this._startingUserDatamodelName
    );
  }

  public hasError = (field: string): boolean => this._formValidationService.hasError(field);

  /**
   * @inheritdoc
   * Check if the _datamodel datamodel is valid. Name is required.
   */
  public get validated(): boolean {
    if (!this.datamodel?.name) {
      return false;
    }

    if (this.datamodel.name.length < 1) {
      return false;
    }

    return true;
  }

  public odmSearchQuery = '';
  public selectedODMs: Datamodel[] = [];

  public get filteredOdms(): Datamodel[] {
    return this.selectedODMs
      .map((datamodel) => ({
        ...datamodel,
        attributes: datamodel.attributes.filter((attribute) =>
          attribute.key.toLowerCase().includes(this.odmSearchQuery.toLowerCase()),
        ),
      }))
      .filter((datamodel) => datamodel.attributes.length);
  }

  constructor(ts?: TranslationService) {
    super();

    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;

    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    this.ts =
      ts ??
      new TranslationService(
        currentSpace.toLowerCase() as 'admin' | 'retailer' | 'supplier' | 'general' | 'global',
        'datamodels',
      );

    this._formValidationService = new FromValidationService();
    this._toastService = ToastService.getInstance();
    this._restService = new DatamodelsRestService();
    this._confirmService = new ConfirmService();

    this.refetch = this.fetchAll;
  }

  public addAllODMsAttributes() {
    this.filteredOdms.forEach((dm) => this.bulkAddAttributes(dm.attributes));
  }

  public fetchAll(): void {
    if (isNegative(this._route.params['id'])) {
      this._toastService.displayErrorToast(this.ts.loadFailed());

      return;
    }

    this.isLoadingParent = true;

    this._restService
      .get(Number(this._route.params['id']))
      .then((res) => {
        this.datamodel = res;
        this._startingUserDatamodelName = res.name;

        this.loadOdmDatamodels();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this.ts.loadFailed());
        this.datamodel = undefined;
      })
      .finally(() => {
        this.isLoadingParent = false;
      });
  }

  public bulkAddAttributes(attributes: Attribute[]): void {
    attributes.forEach((attribute) => {
      this.attributes.set(attribute.id, attribute);
    });
  }

  public bulkRemoveAttributesConfirm(): void {
    this._confirmService.confirmDelete({
      callback: () => {
        this.bulkRemoveAttributes(this.userAttributes);
      },
      group: 'bulkDelete',
      message: this.ts.tModule('warning_removing_attributes'),
    });
  }

  public bulkRemoveAttributes(attributes: Attribute[]): void {
    this.removeWarning = true;

    attributes.forEach((attribute) => {
      this.attributes.delete(attribute.id);
      this.orderedAttributes.delete(attribute.id);
    });
  }

  public addAttribute(attribute: Attribute): void {
    this.attributes.set(attribute.id, attribute);
  }

  public removeAttribute(attribute: Attribute): void {
    this.removeWarning = true;

    this.attributes.delete(attribute.id);
    this.orderedAttributes.delete(attribute.id);
  }

  public resolveCrudComponent(): void {}

  public loadOdmDatamodels(): void {
    this.loadingOdm = true;

    this._restService
      .getAll({ page: 1, limit: 100 }, { only_master: true })
      .then((res) => {
        res.data.map((datamodel) => {
          datamodel.attributes = datamodel.attributes.filter((attr) => attr.parent_id == null);
        });

        this.odmDatamodels = this._removeOrder(res.data);
        this.selectedODMs = this._removeOrder(res.data);

        this.datamodel?.attributes
          ?.filter((attr) => attr.parent_id == null)
          .forEach((attr) => {
            this.addAttribute(attr);
          });
        this.dirtyStateUserAttribute.changeInitialData(this.attributes);
        this.dirtyStateUserAttribute.changeDataToCompare(this.orderedAttributes);
        this.getAllOverrides();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this.ts.loadFailed());
      })
      .finally(() => {
        this.loadingOdm = false;
      });
  }

  private _removeOrder(datamodels: Datamodel[]): Datamodel[] {
    return datamodels.map((datamodel) => {
      datamodel.attributes = datamodel.attributes.map((attr) => {
        delete attr.order;
        return attr;
      });
      return datamodel;
    });
  }

  private _renameDataModel(datamodelId: number, name: string): void {
    // Do not call the function if the name has not been changed
    if (this._startingUserDatamodelName === this.datamodel?.name) {
      return;
    }

    if (!this.validated) {
      this._toastService.displayErrorToast(this.ts.updateFailed());
      return;
    }

    this._restService.update(datamodelId, { id: datamodelId, name: name });
  }

  public async saveUserDatamodel(): Promise<void> {
    if (!this.datamodel || this.datamodel.id == null || this.datamodel.name == null) {
      this._toastService.displayErrorToast(this.ts.updateFailed());

      return;
    }

    try {
      this._renameDataModel(this.datamodel.id, this.datamodel.name);

      if (this.datamodel.id == null || this.datamodel.name == null) {
        this._toastService.displayErrorToast(this.ts.updateFailed());

        return;
      }

      const data = this.orderedUserAttributes.map((attr) => {
        return {
          id: attr.id,
          order: attr?.order ?? 1,
        };
      });

      this._loadingSaveDatamodel = true;

      await this._restService.attachDatamodelFields(this.datamodel.id, {
        attributes: data,
      });
      this._toastService.displaySuccessToast(this.ts.updateSuccess(this.datamodel?.name));
    } catch (error) {
      this._toastService.displayErrorToast(this.ts.updateFailed());
    } finally {
      this.searchKey = '';
      this.odmSearchQuery = '';
      this._loadingSaveDatamodel = false;

      this.refetch();
      this.attributes.clear();
      this.orderedAttributes.clear();
    }
  }

  /** Filter out ODM datamodels that are not used in the new DM  */
  public getOnlyUsedDatemodels(): Datamodel[] {
    const copyOfFilteredAttributes = cloneDeep(this.filteredAttributes);
    return this.odmDatamodels
      .filter(
        (dm: Datamodel) =>
          dm.attributes.filter((attr: Attribute) =>
            copyOfFilteredAttributes.some((dmAttr: Attribute) => attr.id === dmAttr.id),
          ).length > 0,
      )
      .sort((datamodelA: Datamodel, datamodelB: Datamodel) =>
        ('' + datamodelA.name).localeCompare(datamodelB.name),
      );
  }

  public getDatamodelAttributes = (dm: Datamodel): Attribute[] => {
    const copyOfFilteredAttributes = cloneDeep(this.filteredAttributes);

    return copyOfFilteredAttributes
      .filter((userAttr: Attribute) => {
        return dm.attributes.some((dmAttr: Attribute) => userAttr.id === dmAttr.id);
      })
      .sort((attrA: Attribute, attrB: Attribute) =>
        attrA.order! > attrB.order! ? 1 : attrA.order === attrB.order ? 0 : -1,
      );
  };

  public getAllOverrides(): void {
    if (!this.datamodel || this.datamodel.id == null) {
      this._toastService.displayErrorToast(this.ts.loadFailed());
      return;
    }

    this._isLoadingOverview = true;

    this._restService
      .getDatamodelAttributeConfig(this.datamodel.id)
      .then((res) => {
        res.forEach((override) => {
          this.overrides.set(override.attribute_id, override);
        });
      })
      .catch(() => {
        this._toastService.displayErrorToast(this.ts.loadFailed());
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this._isInitialized = true;
      });
  }

  public openOverridedialog(selectedAttribute: Attribute): void {
    this.attributeToOverride = selectedAttribute;

    this.selectedOverride = this.overrides.get(selectedAttribute.id);

    this.showOverride = true;
  }

  /** Close Override form */
  public closeOverrideDialog(): void {
    this.getAllOverrides();
    this.showOverride = false;
  }

  /** Add a new override to the existing object */
  public addOverride(override: DatamodelAttributeConfig): void {
    this.overrides.set(override.attribute_id, override);
  }

  public get overrideIds(): number[] {
    return Array.from(this.overrides.keys());
  }

  public getOriginalAttributeName(attributeId: number): string | undefined {
    const attribute = this.orderedAttributes.get(attributeId);

    return attribute?.key;
  }

  public attributeIsOverrided(attributeId: number): boolean {
    return this.overrideIds.includes(attributeId);
  }

  public selectedAttribuesCountByDatamodel(datamodel: Datamodel): number {
    let commonAttribuesCount = 0;
    datamodel.attributes.forEach(
      (attr) =>
        (commonAttribuesCount += this.orderedUserAttributes
          .map((attrMap) => attrMap.id)
          .includes(attr.id)
          ? 1
          : 0),
    );
    return commonAttribuesCount;
  }

  public isUserAttribute(attributeId: number): boolean {
    return this.orderedUserAttributes.map((attr) => attr.id).includes(attributeId);
  }

  //#region drag and drop
  public orderedAttributes: Map<number, Attribute> = new Map<number, Attribute>();

  public get orderedUserAttributes(): Attribute[] {
    return Array.from(this.orderedAttributes.values());
  }

  public setOrderedAttributes(orderList: OrderableItem<Attribute>[]) {
    orderList.forEach((attr) => {
      if (this.orderedAttributes.get(attr.value.id)) {
        return;
      }

      attr.value.order = attr.order;
      this.orderedAttributes.set(attr.value.id, attr.value);
    });
  }

  public updateOrder(attrId: number, order: number) {
    const attribute = this.orderedAttributes.get(attrId);
    if (!attribute) {
      return;
    }

    attribute.order = order;
    this.orderedAttributes.set(attrId, attribute);
  }

  //#endregion
}
