<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { ValidationRuleItemAttribute } from 'platform-unit2-api/validation-rules';

interface Props {
  linkedAttributes?: ValidationRuleItemAttribute[];
  currentPageReset?: number;
}

const props = withDefaults(defineProps<Props>(), {
  linkedAttributes: () => [],
  currentPageReset: 0,
});

const emit = defineEmits<{ 'update:attribute': [attributeName: string] }>();
const currentPage = ref(0);

function next() {
  if (currentPage.value < (props.linkedAttributes.length ?? 0)) {
    currentPage.value++;
  }

  emit('update:attribute', currentAttribute.value.name);
}

function prev() {
  if (currentPage.value > 0) {
    currentPage.value--;
  }

  emit('update:attribute', currentAttribute.value.name);
}

const currentAttribute = computed(() => {
  return props.linkedAttributes[currentPage.value];
});

watch(
  () => props.currentPageReset,
  () => {
    currentPage.value = 0;
  },
);
</script>
<template>
  <div :class="`align-items-center  flex pup-m-3`">
    <div class="pup-mr-3 pup-my-3">
      <pButton
        icon="mdi mdi-chevron-left"
        class="border-gray-300 border-noround-right border-right-none text-gray-700"
        severity="secondary"
        :disabled="linkedAttributes.indexOf(currentAttribute) === 0"
        @click="prev()"
      />
      <pButton
        icon="mdi mdi-chevron-right"
        class="border-gray-300 border-noround-left text-gray-700"
        severity="secondary"
        :disabled="linkedAttributes.indexOf(currentAttribute) === linkedAttributes.length - 1"
        @click="next()"
      />
    </div>
    <div class="attrs font-bold">
      {{ linkedAttributes.indexOf(currentAttribute) + 1 }} / {{ linkedAttributes?.length }}
      {{ currentAttribute.name }}
    </div>
  </div>
</template>
