<script setup lang="ts">
import { ValidationRule, ValidationRuleItemAttribute } from 'platform-unit2-api/validation-rules';
import ValidationRuleFooter from './validation-rule-footer.vue';
import { inject, ref } from 'vue';
import ReadMore from '@/general/ui/components/read-more.vue';
import { productAttributeValidationServiceKey } from '@/general/services/attribute-fields/service-keys';

interface Props {
  severity: string;
  validationRules: (ValidationRule & { linkedAttributes: ValidationRuleItemAttribute[] })[];
}

const props = defineProps<Props>();
const productAttributesValidationService = inject(productAttributeValidationServiceKey)!;
const selectedItem = ref<number | undefined>(undefined);
function selectItem(
  validationRule: ValidationRule & { linkedAttributes: ValidationRuleItemAttribute[] },
) {
  if (selectedItem.value !== validationRule.id) {
    selectedItem.value = validationRule.id;
    productAttributesValidationService.value.selectedValidationPanelTab = props.severity;
    scrollToAttribute(validationRule.linkedAttributes[0].name);
  }
}

function scrollToAttribute(attributeName: string) {
  document
    .getElementById(attributeName)
    ?.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'center' });
}
</script>
<template>
  <div
    v-for="rule in validationRules"
    :key="rule.id"
    :class="`bg-${
      severity === 'Error' ? 'red' : 'orange'
    }-100 ${severity.toLocaleLowerCase()}-list carousel ${
      selectedItem === rule?.id &&
      productAttributesValidationService.selectedValidationPanelTab === props.severity
        ? 'selectedItem'
        : ''
    }`"
    @click="selectItem(rule)"
  >
    <section>
      <div :class="`${severity.toLocaleLowerCase()}-item pup-p-4`">
        <div :class="`${severity.toLocaleLowerCase()}-item-title font-bold pup-my-3`">
          {{ rule.name }}
        </div>
        <ReadMore :text="rule.error_message" :char-count="200" :line-count="3" />
      </div>
    </section>
    <section>
      <ValidationRuleFooter
        :linked-attributes="rule.linkedAttributes"
        :current-page-reset="selectedItem"
        @update:attribute="(val: string) => scrollToAttribute(val)"
      />
    </section>
  </div>
</template>
<style lang="scss" scoped>
.error-list {
  background-color: #f9c2ca !important;
  opacity: 0.85;
  border: solid 1px #f9c2ca;
}

.warning-list {
  background-color: #ffdcc4 !important;
  opacity: 0.85;
  border: solid 1px #ffdcc4;
}

.carousel:hover {
  opacity: 0.75;
  cursor: pointer;
}

.selectedItem {
  opacity: 1 !important;
  &.error-list {
    border: solid 1px #f04359;
  }
  &.warning-list {
    border: solid 1px #f08b43;
  }
}
</style>
