<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import UserAvatar from 'ui/components/user-avatar.vue';
import UserAvatarGroup from 'ui/components/user-avatar-group.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import StatusChip from '@/general/ui/components/status-chip.vue';

import { User } from 'platform-unit2-api/users';
import { useStore } from 'vuex';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { useRouter } from 'vue-router';
import { Action, ActionRestService } from 'platform-unit2-api/actions';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { formatDate } from '@/general/utils/format-date';

//Services
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'dashboard');
const actionApi = new ActionRestService();
const store = useStore();
const currentUser: User | undefined = store.getters['users/currentUser'];
const router = useRouter();

/** Constants */
const loading = ref(true);
const tasks = ref<Action[]>([]);

const total = computed(() => {
  return tasks.value.length;
});

const loadAsyncData = async (): Promise<void> => {
  loading.value = true;

  try {
    tasks.value = (await actionApi.getAll()).data;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

/** LifeCycle Methods */
onMounted(() => {
  loadAsyncData();
});
</script>
<template>
  <p-card>
    <template #content>
      <h4 class="mb-4 text-left">Tasks <p-badge v-if="total !== 0" :value="tasks.length" /></h4>
      <pSkeleton v-if="loading" class="h-5rem my-3" />
      <pSkeleton v-if="loading" class="h-5rem my-3" />
      <pSkeleton v-if="loading" class="h-5rem my-3" />
      <div v-else-if="tasks.length === 0">
        <EmptyState
          :translation-service="ts"
          :icon-name="'tasks'"
          :empty-state-title="ts.tModule('emptyState.tasks.title')"
          :empty-state-subtitle="ts.tModule('emptyState.tasks.subtitle')"
          :button-label="ts.tModule('emptyState.tasks.button')"
          :button-icon="'mdi mdi-plus'"
          @clicked="router.push({ name: 'new-task' })"
        />
      </div>
      <div
        v-for="task in tasks.slice(0, 5)"
        v-else
        :key="task.id"
        :loading="loading"
        class="align-items-center border-1 border-100 cursor-pointer flex flex-row hover:surface-50 justify-content-between mb-3 p-3"
        @click="$router.push({ name: 'update-task', params: { id: task.id } })"
      >
        <div class="mr-3" style="max-width: 25%">
          <h2 class="mb-1 overflow-hidden text-lg text-overflow-ellipsis white-space-nowrap">
            {{ task.title }}
          </h2>
          <p style="text-overflow: ellipsis" class="overflow-hidden white-space-nowrap">
            {{ task.description }}
          </p>
        </div>
        <div class="align-items-center flex flex-row">
          <div class="mr-3">
            <StatusChip
              :label="task.status?.label"
              :severity="ActionStatusSeverityEnum[task.status?.label.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
              label-only
            />
          </div>
          <div class="mx-3 pup-w-5 text-left">
            <UserAvatarGroup v-if="task.users" :users="task.users" />

            <pAvatarGroup v-if="task?.assigned_to_workspace && currentUser?.workspace?.name">
              <UserAvatar
                v-tooltip.bottom="{ value: currentUser?.workspace?.name }"
                :username="currentUser?.workspace?.name"
                :url="
                  currentUser?.workspace?.thumbnail?.public_thumbnail_url ??
                  currentUser?.workspace?.thumbnail?.public_url
                "
                size="normal"
              />
            </pAvatarGroup>
          </div>
          <div class="ml-auto mr-3">
            <p>{{ formatDate(new Date(task?.date)) }}</p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="bottom-0 flex justify-content-end mt-5 sticky">
        <div class="flex">
          <p-button
            class="md:ml-auto md:w-auto w-full"
            label="View all tasks"
            @click="$router.push({ name: 'tasks' })"
          />
        </div>
      </div>
    </template>
  </p-card>
</template>
