<script setup lang="ts">
/* eslint-disable vue/valid-attribute-name */

//Components
import Upload from '@/general/ui/components/upload.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';

//Types
import { User } from 'platform-unit2-api/users';
import { Pipeline, PipelineStageEnum, PipelinesRestService } from 'platform-unit2-api/pipelines';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';

//Core
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';

//Composables and Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import usePagination from 'composables/usePagination/pagination';
import {
  DataTableFilterEvent,
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primevue/datatable';
import ExportService from 'retailer/modules/export/services/export.service';

import ExportDetails from '@/platforms/retailer/modules/export/views/export-details-v2.view.vue';
import useSortable from 'composables/sortable';
import { MenuItem } from 'primevue/menuitem';
import useSearchable from 'composables/searchable';
import { Enumerated } from 'platform-unit2-api/core';
import { ChannelFilter } from 'retailer/modules/export/ts/interfaces/channel-filter.interface';
import { ServiceExportFilter } from 'retailer/modules/export/ts/interfaces/service-export-filter.interface';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import { formatDate } from '@/general/utils/format-date';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'exports');
const confirmService = new ConfirmService();
const pipelineApi = new PipelinesRestService();

/** Constants */
const store = useStore();
const { sort, onSortChange: onSortChange } = useSortable();
const { page, perPage, onPageChange: onPageChange } = usePagination();
const { query, onSearch: $onSearch } = useSearchable();
const router = useRouter();
const route = useRoute();
const exportService = new ExportService();

const loading = ref(true);
const showSidebar = ref<boolean>(false);
let timer!: ReturnType<typeof setInterval>;

const pipelines = ref<Pipeline[]>([]);
const total = ref<number>(0);
const searchQuery = ref('');
let searchTimeout!: ReturnType<typeof setTimeout>;
const displayExportDetailsModal = ref<boolean>(false);
const selectedPipelineId = ref<number>();
const statusOptions = ref<Enumerated[]>();
const usernameOptions = ref<Enumerated[]>();
const usernameOptionsRaw = ref<Enumerated>({});
const channelFilterOptions = ref<ChannelFilter[]>();
const selectedItems = ref<ChannelFilter[]>();
const tableFilters = ref<DataTableFilterEvent>();
const filterObject = ref<ServiceExportFilter | undefined>({
  filterStatus: '',
  filterUsername: '',
  filterChannel: '',
  filterGtin: '',
  filterValue: '',
});
let activeFilters = false;
const clearableFilters: string[] = ['status', 'userName'];

const currentUser = computed((): User => {
  return store.getters['users/currentUser'];
});

const hideDetails = (): void => {
  showSidebar.value = false;
  router.push({
    name: 'export' as string,
  } as RouteLocationRaw);
};

const loadAsyncData = async (showLoadingIcon = true): Promise<void> => {
  loading.value = showLoadingIcon;
  try {
    const response = await pipelineApi.getPipelines(
      { page: page.value, limit: perPage.value },
      query.value,
      sort.value,
      filterObject.value?.filterValue,
      PipelineStageEnum.EXPORT,
    );
    pipelines.value = response.data;
    if (response.meta !== undefined) {
      total.value = response.meta.total;
    }

    loading.value = false;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }

  loading.value = false;
};

const onSearch = (query: string) => {
  page.value = 1;
  $onSearch(query, loadAsyncData);
};

const search = (event: KeyboardEvent | MouseEvent) => {
  clearTimeout(searchTimeout);
  if ((event instanceof KeyboardEvent && event.keyCode === 13) || event instanceof MouseEvent) {
    onSearch(searchQuery.value);
  } else {
    searchTimeout = setTimeout(() => {
      onSearch(searchQuery.value);
    }, 500);
  }
};

/* Menu implementation for non-pending status */

const menuItems = (pipeline: Pipeline): MenuItem[] => [
  {
    label: ts.tModule('actionMenu.download'),
    icon: 'mdi mdi-tray-arrow-down',
    command: () => {
      exportService.openDownload(pipeline);
    },
  },
  {
    label: ts.tModule('actionMenu.goTo'),
    icon: 'mdi mdi-open-in-new',
    command: () => {
      exportService.goToProducts(pipeline?.id);
    },
  },
  {
    label: ts.tModule('actionMenu.restartWhole'),
    icon: 'mdi mdi-restart',
    command: () => {
      exportService.restartExport(pipeline?.id);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tModule('actionMenu.delete'),
    class: 'delete',
    icon: 'mdi mdi-delete',
    command: () => {
      confirmExportDelete(pipeline?.id);
    },
  },
];

const confirmExportDelete = (id: number) => {
  confirmService.confirmDelete({
    callback: () => deleteExport(id),
    group: 'pipelines',
    message: ts.deleteConfirm(),
  });
};

const deleteExport = async (id: number | undefined): Promise<void> => {
  try {
    if (id === undefined) {
      return;
    }

    await pipelineApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch {
    toastService.displayErrorToast(ts.tModule('tooltips.missingPipelineId'));
  }
};

const handlePageChange = (event: DataTablePageEvent) => {
  onPageChange(event.page + 1, loadAsyncData);
};

const handleSortChange = (event: DataTableSortEvent) => {
  if (event.sortField != null && event.sortOrder != null) {
    event.sortField = (event.sortField as string).replace('.', '_');
    const sortKey = (event.sortOrder < 0 ? '-' : '') + event.sortField;
    onSortChange(sortKey, loadAsyncData);
  }
};

const displayDetails = (event: DataTableRowClickEvent) => {
  if (event.data.id) {
    selectedPipelineId.value = event.data.id!;
    displayExportDetailsModal.value = true;
  }
};

const filters = ref(exportService.resetFilters(clearableFilters));

const applyFilter = (reset?: boolean, event?: DataTableFilterEvent) => {
  if (event) {
    tableFilters.value = event;
  }

  filterObject.value = exportService.applyFilter(
    reset,
    tableFilters.value,
    filterObject.value,
    usernameOptionsRaw,
    selectedItems.value,
  );

  activeFilters = !!filterObject.value?.filterValue;

  loadAsyncData();
};

const loadFilters = async () => {
  const usernameRestApiResponse = await pipelineApi.getFilterUsernames();
  const channelsRestApiResponse = await pipelineApi.getFilterChannels();
  const statusRestApiResponse = await pipelineApi.getFilterStatuses();
  type ObjectKey = keyof typeof usernameRestApiResponse;
  const key = 'data' as ObjectKey;
  usernameOptionsRaw.value = Object.values(usernameRestApiResponse)[0] as Enumerated;
  usernameOptions.value = Object.values(usernameRestApiResponse[key]) as Enumerated[];
  statusOptions.value = Object.values(statusRestApiResponse) as Enumerated[];
  channelFilterOptions.value = Object.values(
    channelsRestApiResponse[key as any],
  ) as ChannelFilter[];
};

/** Lifecycle */
watch(
  () => route,
  () => {
    if (route.name === 'restart-export') {
      showSidebar.value = true;
    }

    if (route.name === 'export') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

onBeforeMount(async () => {
  sort.value = 'owner_name';
  loading.value = true;
});

onMounted(async () => {
  timer = setInterval(loadAsyncData.bind(this, false), 5000);
  await loadFilters();
  exportService.formatStatus(statusOptions.value);
  await loadAsyncData();
});

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<template>
  <section class="h-full pt-3 px-4">
    <div v-if="!loading" class="multi-select">
      <pMultiselect
        v-model="selectedItems"
        :options="channelFilterOptions"
        :placeholder="ts.tModule('tooltips.channel_filter_placeholder')"
        filter
        option-label="name"
        class="mb-3 md:w-20rem w-full"
        @change="applyFilter(false)"
      />
    </div>
    <div v-if="!loading" class="flex search-wrapper">
      <p-input-text
        v-model="searchQuery"
        class="border-noround-right border-right-none border-round-md w-full"
        type="text"
        :placeholder="ts.tModule('tooltips.searchPlaceholder')"
        @input="search"
      />
      <p-button
        class="bg-white border-200 border-noround-left p-button-sm p-inputgroup-addon p-inputtext"
        :disabled="!activeFilters"
        @click="applyFilter(true)"
        ><i class="mdi mdi-filter-remove-outline text-800 text-xl"></i
      ></p-button>
    </div>
    <pDataTable
      v-model:filters="filters"
      filter-display="menu"
      class="overflow-y-visible"
      scrollable
      scroll-height="flex"
      :value="pipelines"
      :row-hover="true"
      :lazy="true"
      :paginator="true"
      :loading="loading"
      :rows="perPage"
      :total-records="total"
      :first="(page - 1) * perPage"
      :global-filter-fields="['current_status, user_name']"
      data-key="id"
      @page="handlePageChange"
      @sort="handleSortChange"
      @row-click="displayDetails"
      @filter="(event: DataTableFilterEvent) => applyFilter(false,event)"
    >
      <template #loading>
        <TableSkeleton class="w-full" />
      </template>
      <!-- #region column names-->

      <p-column :header="ts.tModule('table_header.name')" width="1" field="owner.name">
        <template #body="{ data }">
          <span class="table-column_name">
            <upload class="w-4rem" :upload="data?.retailer?.thumbnail" />
            <span v-if="data.module" class="table-column_name_owner-name">
              {{ exportService.formatName(data.module ? data.module.name : ts.tGlobal('system')) }}
            </span>
            <span v-else class="table-column_name_owner-name"></span>
          </span>
        </template>
      </p-column>
      <p-column :header="ts.tModule('table_header.products')" width="1" field="product_count">
        <template #body="{ data }">
          <span
            v-if="data.product_count !== null && data.payload.parameters.product_ids != null"
            class="table-column_products"
          >
            {{ exportService.getProductCount(data.payload.parameters.product_ids) }}
          </span>
        </template>
      </p-column>
      <p-column :header="ts.tModule('table_header.by')" width="1" field="userName">
        <template #body="{ data }">
          <div v-if="data.user">
            {{
              data.user != null
                ? currentUser && data.user.id === currentUser.id
                  ? ts.tGlobal('me')
                  : data.user.name
                : ts.tGlobal('system')
            }}
          </div>
          <div v-else>{{ ts.tGlobal('unknown_user') }}</div>
        </template>
        <template #filter="{ filterModel }">
          <p-dropdown
            v-model="filterModel.value"
            :options="usernameOptions"
            placeholder="Search filters"
            display="chip"
            class="w-full"
          />
        </template>
      </p-column>
      <p-column
        :header="ts.tModule('table_header.date_and_time')"
        width="1"
        :sortable="true"
        :field="'created_at'"
      >
        <template #body="{ data }">
          {{ formatDate(data.created_at) }}
        </template>
      </p-column>
      <p-column :header="ts.tModule('table_header.status')" width="1" field="status">
        <template #body="{ data }">
          <span v-if="data.current_status">
            <StatusChip
              :label="exportService.processStatus(data.current_status)"
              :severity="ActionStatusSeverityEnum[exportService.processStatus(data.current_status).toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
            />
          </span>
        </template>

        <template #filter="{ filterModel }">
          <!-- <TableFilters /> -->
          <p-dropdown
            v-model="filterModel.value"
            :options="statusOptions"
            placeholder="Search filters"
            display="chip"
            class="w-full"
          />
        </template>
      </p-column>
      <p-column :header="ts.tModule('table_header.errors')" width="1">
        <template #body="{ data }">
          <span v-if="data.error_count >= 0" class="table-column_errors">
            <img v-if="data.error_count" src="@/assets/icons/error-icon.svg" alt="" />
            <span class="text">{{ data.error_count }}</span>
          </span>
        </template>
      </p-column>
      <p-column :header="ts.tModule('table_header.actions')" width="1">
        <template #body="slotProps: { data: Pipeline }">
          <ActionsMenu :menu-items="menuItems(slotProps.data)" />
        </template>
      </p-column>

      <!-- empty table message-->
      <template #empty>
        <EmptyState
          :translation-service="ts"
          :button-visible="true"
          :button-label="ts.tGlobal('productOverview')"
          :icon-name="'exports'"
          @clicked="exportService.goToProducts()"
        />
      </template>
    </pDataTable>
    <BaseDialog
      :visible="displayExportDetailsModal"
      :title="ts.tModule('details.title')"
      :show-footer="false"
      size="large"
      @update:visible="displayExportDetailsModal = false"
    >
      <ExportDetails :pipeline-id="selectedPipelineId"></ExportDetails>
    </BaseDialog>
    <pSidebar
      v-model:visible="showSidebar"
      class="p-sidebar-md sidebar-crud"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @load-async-data="loadAsyncData" />
    </pSidebar>
    <p-confirm-dialog group="pipelines" />
  </section>
</template>

<style lang="scss" scoped>
:deep .p-datatable-table {
  border: 1px solid var(--greyscale-black-300);
}
.p-datatable-loading-overlay {
  position: relative;
}

:deep .p-datatable-thead {
  border: 1px solid var(--greyscale-black-300);

  :deep th {
    border: 1px solid var(--greyscale-black-300);
  }
}

:deep th:not(:last-child):after {
  content: '';
  height: 60%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 7px;
  background-color: var(--greyscale-black-300);
}

:deep th {
  position: relative;
  padding: 14px;
}

:deep .p-datatable tr:not(:last-child) {
  td {
    border: 1px solid var(--greyscale-black-300);
  }
}

:deep tr:last-child {
  td {
    border: unset !important;
  }
}

:deep .pi-sort-alt:before {
  font-weight: bold;
  color: var(--greyscale-black-600);
}

.table-column_name {
  display: flex;
  align-items: center;

  :deep img {
    height: 24px;
    width: 24px;
    border: 1px solid lightgray;
    border-radius: 30px !important;
  }

  .table-column_name_owner-name {
    padding-left: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
  }
}

.table-column_products {
  color: var(--greyscale-black-600) !important;
}

:deep .p-column-title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--greyscale-black-600) !important;
}

.table-column_errors {
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
}

.table-column_actions {
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

:deep .mdi-dots-vertical::before {
  font-size: 21px;
  position: relative;
  top: -4px;
}

.search-wrapper {
  padding: 12px;
}

:deep .p-column-filter-menu {
  margin-left: 6px;
}
</style>

<style lang="scss">
.p-menu-overlay {
  min-width: 170px !important;

  .p-menuitem-link {
    display: flex;
    align-items: center;
  }

  .p-menuitem-icon {
    height: 9px;
    width: 9px;
    color: var(--table-border-grey) !important;
    margin-right: 10px !important;

    &:before {
      position: relative;
      top: -3px;
    }
  }

  .p-menuitem-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--table-border-grey);
  }
}

.p-column-filter-matchmode-dropdown {
  display: none !important;
}

.dialog-styles .p-dialog-content {
  width: 80vw;
  height: 70vh;
}

.dialog-styles .p-dialog-header {
  width: 80vw;
}

#actionsMenu {
  .p-menuitem:last-child {
    .p-menuitem-link {
      span,
      &:before {
        color: #ac110c !important;
        line-height: 14px !important;
      }
    }
  }
}
</style>
