export class NavigationSidebarService {
  static _instance: NavigationSidebarService;
  private isNavigationOpen = false;

  static getNavigationSidebarService() {
    if (!NavigationSidebarService._instance) {
      this._instance = new NavigationSidebarService();
    }

    return NavigationSidebarService._instance;
  }

  constructor() {
    this.isNavigationOpen = this._getFromStorage();
  }

  get isNavOpen() {
    return this.isNavigationOpen;
  }

  public setNavigationStatus(status: boolean) {
    this.isNavigationOpen = status;
    this._setInStorage(status);
  }

  private _getFromStorage() {
    return localStorage.getItem('navigation_collapsed') === 'true';
  }
  private _setInStorage(status: boolean) {
    localStorage.setItem('navigation_collapsed', JSON.stringify(status));
  }
}
