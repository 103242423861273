<script setup lang="ts">
import ExportService from 'retailer/modules/export/services/export.service';
import { Pipeline } from 'platform-unit2-api/pipelines';
import { DataTableFilterEvent } from 'primevue/datatable';
import usePagination from 'composables/usePagination/pagination';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from 'services/translations/translation.service';
import { onMounted, ref } from 'vue';
import { ServiceExportFilter } from 'retailer/modules/export/ts/interfaces/service-export-filter.interface';
import { Enumerated } from 'platform-unit2-api/core';
import { PipelineMessage, PipelinesRestService } from 'platform-unit2-api/pipelines';
import { DataTablePageEvent } from 'primevue/datatable';
import { PipelineGtins } from 'retailer/modules/export/ts/interfaces/pipeline-gtins.interface';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { formatDate } from '@/general/utils/format-date';

interface Props {
  pipelineId: number | undefined;
  pipeline: Pipeline | undefined;
}
/** Initialize services. */
const props = defineProps<Props>();
const { page, perPage, onPageChange: onPageChange } = usePagination();
const exportService = new ExportService();
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'exports');
const pipelineApi = new PipelinesRestService();

/** Constants. */
const rawCode = ref();
const loading = ref<boolean>(false);
const expandedRows = ref<number[]>([]);
const pipelineMessages = ref<PipelineMessage[]>([]);
const total = ref<number>(0);
const logCounter = ref<number>(0);
const gtinOptions = ref<Enumerated[]>();
const filters = ref(exportService.resetFilters(['gtin']));
const filterObject = ref<ServiceExportFilter | undefined>({
  filterStatus: '',
  filterUsername: '',
  filterChannel: '',
  filterGtin: '',
  filterValue: '',
});

/**
 * Loads all pipeline messages for the given pipeline.
 * @param showLoadingIcon
 */
const loadAsyncData = async (showLoadingIcon = true): Promise<void> => {
  loading.value = showLoadingIcon;
  try {
    const pipelineMessagesResponse = await exportService.getPipelineMessages({
      page: page.value,
      pipelineId: props.pipelineId,
      filterParam: filterObject?.value?.filterValue,
    });

    pipelineMessages.value = pipelineMessagesResponse.data;
    if (pipelineMessagesResponse.meta === undefined) {
      return;
    }

    logCounter.value = pipelineMessagesResponse.meta.total;
    total.value = pipelineMessagesResponse.meta.total;
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('tost.export_pipeline_messages_failed'));
  }

  loading.value = false;
};

/**
 * Loads and processes filters needed for GTIN column filtering
 * @param showLoadingIcon
 */
const loadFilters = async (showLoadingIcon = true): Promise<void> => {
  loading.value = showLoadingIcon;
  try {
    if (!props.pipelineId) {
      return;
    }

    const gtinsRestApiResponse = await pipelineApi.getFilterGtins(props.pipelineId);
    type ObjectKey = keyof typeof gtinsRestApiResponse;
    const key = 'data' as ObjectKey;
    const filterGtins: PipelineGtins[] = Object.values(gtinsRestApiResponse[key]) as any;
    const gtinList: string[] = [];

    filterGtins.filter((el: PipelineGtins) => {
      gtinList.push(el.gtin);
    });

    gtinOptions.value = gtinList;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tModule('toast.export_filter_gtin_failed')));
  }

  loading.value = false;
};

/**
 * Handles the pagination change
 * @param event
 */
const handlePageChange = (event: any) => {
  onPageChange(event.page + 1, loadAsyncData);
};

/**
 * Copies raw code from export log details, to clipboard
 */
const copyToClipboard = () => {
  exportService.copyToClipboard(rawCode.value.innerText);
  toastService.displaySuccessToast(ts.tModule('tooltips.copyToClipboardSuccess'));
};

/**
 * Applies table filters using export service
 * @param event
 * @param reset
 */
const applyFilter = (event: DataTableFilterEvent, reset?: boolean) => {
  filterObject.value = exportService.applyFilter(
    reset,
    event,
    filterObject.value,
    gtinOptions.value,
  );

  loadAsyncData();
};

onMounted(async () => {
  await loadFilters();
  await loadAsyncData();
});
</script>

<template>
  <p-data-table
    v-model:expanded-rows="expandedRows"
    v-model:filters="filters"
    class="overflow-hidden"
    filter-display="menu"
    :value="pipelineMessages"
    :row-hover="true"
    :loading="loading"
    :lazy="true"
    :paginator="total > perPage"
    :rows="perPage"
    :total-records="total"
    :first="(page - 1) * perPage"
    data-key="id"
    @filter="applyFilter($event, false)"
    @page="(event: DataTablePageEvent) => handlePageChange(event)"
  >
    <p-column :expander="true" />
    <!-- #region column names-->
    <p-column :header="ts.tModule('details_modal.gtin')" width="1" field="gtin">
      <template #body="slotProps">
        <span v-if="slotProps?.data?.product?.gtin" class="table-column_gtin">
          {{ slotProps.data.product.gtin }}
        </span>
      </template>
      <template #filter="{ filterModel }">
        <p-dropdown
          v-model="filterModel.value"
          :options="gtinOptions"
          placeholder="Search filters"
          display="chip"
          class="w-full"
        />
      </template>
    </p-column>
    <p-column :header="ts.tModule('details_modal.message')" field="retailer">
      <template #body="slotProps">
        <span v-if="slotProps?.data?.message_type" class="table-column_message">
          {{ exportService.processMessageType(slotProps.data.message_type) }}
        </span>
      </template>
    </p-column>
    <p-column field="user" :header="ts.tModule('details_modal.status')">
      <template #body>
        <span class="text">Failed</span>
      </template>
    </p-column>
    <p-column field="status" :header="ts.tModule('details_modal.timestamp')">
      <template #body="slotProps">
        <span v-if="slotProps?.data?.created_at">
          {{ formatDate(slotProps.data.created_at) }}
        </span>
      </template>
    </p-column>
    <!-- #endregion-->

    <!-- empty table message-->
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-visible="false"
        :button-label="ts.tGlobal('productOverview')"
        :icon-name="'exports'"
        :empty-state-title="ts.tModule('details_modal.empty_table_title')"
        :empty-state-subtitle="ts.tModule('details_modal.empty_table_subtitle')"
        @clicked="exportService.goToProducts()"
      />
    </template>
    <!-- region dropdown expansion per export-->
    <template #expansion="slotProps">
      <div class="log-details">
        <div class="product">
          <div v-if="slotProps?.data?.submission_id" class="text">
            {{ ts.tModule('details_modal.submission_id') }}:
            {{ slotProps.data.submission_id }}
          </div>
          <div v-if="slotProps?.data?.product?.id" class="link">
            <router-link
              :to="{
                name: 'product-attributes',
                params: {
                  id: slotProps.data.product.id,
                },
              }"
            >
              <p-button
                :label="ts.tModule('details_modal.goto')"
                icon="pi pi-external-link"
                class="p-button-outlined"
              />
            </router-link>
          </div>
        </div>
        <div class="message">
          <div class="title">{{ ts.tModule('details_modal.message') }}:</div>
          <div v-if="slotProps?.data?.message" class="text">{{ slotProps.data.message }}</div>
        </div>
        <div class="code">
          <div class="title">{{ ts.tModule('details_modal.raw_code') }}:</div>
          <div v-if="slotProps?.data?.raw_payload" class="code-raw">
            <pre ref="rawCode">
              {{ JSON.stringify(JSON.parse(slotProps.data.raw_payload), undefined, 2) }}
                <p-button
                  icon="pi pi-copy"
                  class="p-button-copy"
                  @click="copyToClipboard()"
                />
            </pre>
          </div>
        </div>
      </div>
      <!-- endregion-->
    </template>
  </p-data-table>
</template>

<style lang="scss" scoped>
:deep .p-datatable-thead {
  border: 1px solid var(--greyscale-black-300) !important;

  :deep th {
    border: 2px solid var(--greyscale-black-300);
  }
}

:deep th:after {
  content: '';
  height: 60%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 7px;
  background-color: var(--greyscale-black-300);
}

:deep th:last-child:after {
  width: 0;
}

:deep th {
  position: relative;
  padding: 5px 12px !important;

  &:nth-child(1) {
    &:after {
      width: 0 !important;
    }
  }

  &:nth-child(2) {
    .p-column-header-content {
      left: -30px;
      position: relative;
    }
  }
}

:deep tr {
  border: 1px solid var(--greyscale-black-300);
}

:deep td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: var(--table-border-grey);
  padding: 5px 10px !important;

  &:nth-child(1) {
    padding: 5px 0 5px 10px !important;
    border-left: 1px solid #f35c56 !important;
  }

  &:nth-child(2) {
    padding: 5px 10px 5px 0 !important;

    :deep .p-column-title {
      padding-left: 0 !important;
    }
  }

  &:nth-child(3) {
    min-width: 800px;
  }
}

.log-details {
  padding: 25px;

  .product {
    display: flex;
    justify-content: space-between;

    button {
      border: 1px solid #cad9e7;
      border-radius: 3px;
      color: #335270;

      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 16px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 8px;
  }

  pre {
    background-color: #172533;
    border: 1px solid #335270;
    border-radius: 3px;
    color: #fff;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

:deep .p-column-filter-menu {
  margin-left: 6px;
}

:deep .p-datatable-wrapper {
  height: unset;
  padding-bottom: unset;
}

:deep .p-paginator-bottom {
  bottom: -28px !important;
}

pre {
  position: relative;
}

.p-button-copy {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 0;
  right: 0;
  background: #223950;
  border: 1px solid #335270;
}

.p-datatable.p-component {
  height: unset;
}
</style>
