import { MenuItemCommandEvent } from 'primevue/menuitem';
import { TranslationService } from '@/general/services/translations/translation.service';
import { useConfirm } from 'primevue/useconfirm';
import { ConfirmationOptions } from 'primevue/confirmationoptions';

/**
 * This service is used to display a confirmation dialog.
 */
export class ConfirmService {
  private _confirm: {
    require: (option: ConfirmationOptions) => void;
    close: () => void;
  };

  private ts: TranslationService;

  constructor(confirm = useConfirm(), ts = new TranslationService('global')) {
    this._confirm = confirm;
    this.ts = ts;
  }

  /**
   * Opens a confirmation dialog to delete
   * @param options.event The event that is triggered when the user clicks on the delete button
   * @param options.callback The function to be ran
   * @param options.group the group of the confirmation dialog
   * @param options.message the message of the confirmation dialog
   * @param options.header the header of the confirmation dialog
   * @param options.acceptLabel the accept label of the confirmation dialog
   * @param options.rejectLabel the reject label of the confirmation dialog
   */
  public confirmDelete(options: {
    event?: PointerEvent | MenuItemCommandEvent;
    callback: Function;
    group?: string;
    message?: string;
    header?: string;
    acceptLabel?: string;
    rejectLabel?: string;
    icon?: string;
  }) {
    this._confirm.require({
      target: options.event?.currentTarget as HTMLElement,
      group: options.group,
      icon: options.icon ?? 'mdi mdi-information-outline',
      message: options.message ?? this.ts.deleteConfirm(),
      acceptClass: 'p-button-danger',
      rejectClass: 'p-button-plain p-button-text',
      header: options.header ?? this.ts.tGlobal('delete'),
      //The await in most cases may be unnecessary,
      //but it is added to be sure is awaited if its an async function.
      accept: async () => await options.callback(),
      acceptLabel: options.acceptLabel ?? this.ts.tGlobal('yes'),
      rejectLabel: options.rejectLabel ?? this.ts.tGlobal('no'),
    });
  }

  /**
   * Opens a confirmation dialog to save
   * @param options.event The event that is triggered when the user clicks on the delete button
   * @param options.callback The function to be ran
   * @param options.group the group of the confirmation dialog
   * @param options.message the message of the confirmation dialog
   * @param options.header the header of the confirmation dialog
   */
  public confirmSave(options: {
    event?: PointerEvent;
    callback: () => void;
    group?: string;
    message?: string;
    header?: string;
  }) {
    this._confirm.require({
      target: options.event?.currentTarget as HTMLElement,
      group: options.group,
      header: options.header ?? this.ts.tGlobal('save'),
      icon: 'mdi mdi-information-outline',
      message: options.message ?? this.ts.saveConfirm(),
      acceptClass: 'p-button-primary',
      rejectClass: 'p-button-plain p-button-text ',
      //The await in most cases may be unnecessary,
      //but it is added to be sure is awaited if its an async function.
      accept: async () => await options.callback(),
    });
  }

  /**
   * Opens a confirmation dialog to duplicate
   * @param options.event The event that is triggered when the user clicks on the delete button
   * @param options.callback The function to be ran
   * @param options.group the group of the confirmation dialog
   * @param options.message the message of the confirmation dialog
   * @param options.header the header of the confirmation dialog
   */
  public confirmDuplicate(options: {
    event?: PointerEvent;
    callback: () => void;
    group?: string;
    message?: string;
    header?: string;
  }) {
    this._confirm.require({
      header: options.header ?? this.ts.tGlobal('duplicate'),
      target: options.event?.currentTarget as HTMLElement,
      group: options.group,
      icon: 'mdi mdi-alert-outline',
      message: options.message ?? this.ts.duplicateConfirm(),
      acceptClass: 'p-button-success',
      rejectClass: 'p-button-plain p-button-text',
      //The await in most cases may be unnecessary,
      //but it is added to be sure is awaited if its an async function.
      accept: async () => await options.callback(),
    });
  }

  /**
   * Opens a confirmation dialog to reset
   * @param options.event The event that is triggered when the user clicks on the delete button
   * @param options.callback The function to be ran
   * @param options.rejectCallback The function to be ran when the action is cancelled
   * @param options.group the group of the confirmation dialog
   * @param options.message the message of the confirmation dialog
   * @param options.header the header of the confirmation dialog
   */
  public confirmReset(options: {
    event?: PointerEvent;
    callback: () => void;
    rejectCallback?: () => void;
    group?: string;
    message?: string;
    header?: string;
  }) {
    this._confirm.require({
      header: options.header ?? this.ts.tGlobal('reset'),
      target: options.event?.currentTarget as HTMLElement,
      group: options.group,
      icon: 'mdi mdi-information-outline',
      message: options.message ?? this.ts.tGlobal('revert'),
      acceptClass: 'p-button-danger',
      rejectClass: 'p-button-plain p-button-text',
      //The await in most cases may be unnecessary,
      //but it is added to be sure is awaited if its an async function.
      accept: async () => await options.callback(),
      reject: async () => await options.rejectCallback?.(),
    });
  }

  /**
   * Opens a dirty state dialog
   * @param options.event The event that is triggered when the user clicks on the delete button
   * @param options.accept The function to be ran
   * @param options.reject the function that is ran when the action is cancelled
   * @param options.group the group of the confirmation dialog
   * @param options.message the message of the confirmation dialog
   * @param options.header the header of the confirmation dialog
   */
  public dirtyState(options: {
    event?: PointerEvent;
    accept: () => void;
    reject: () => void;
    group?: string;
    message?: string;
    header?: string;
    titleParam?: string;
    messageParams?: {
      action: string;
      entity: string;
    };
  }) {
    this._confirm.require({
      header:
        options.header ??
        this.ts.tGlobal('dirtyState', { params: { action: options?.titleParam } }),
      target: options.event?.currentTarget as HTMLElement,
      group: options.group,
      icon: 'mdi mdi-alert-outline',
      message:
        options.message ??
        this.ts.tGlobal('dirtyStateMessage', {
          params: { action: options.messageParams?.action, entity: options.messageParams?.entity },
        }),
      acceptClass: 'p-button-danger',
      rejectClass: 'p-button-plain p-button-text',
      acceptLabel: this.ts.tGlobal('leave'),
      rejectLabel: this.ts.tGlobal('continue'), //The await in most cases may be unnecessary,
      //but it is added to be sure is awaited if its an async function.
      accept: async () => {
        await options.accept();
      },
      reject: async () => {
        await options.reject();
      },
    });
  }

  //TODO add confirm Create or update or leave
}
