import { CategoryRestService } from 'platform-unit2-api/categories';
import { CrudDataFilterOption } from 'platform-unit2-api/core';
import { Locale } from 'platform-unit2-api/locales';
import { ProductFieldsRestService, ProductField } from 'platform-unit2-api/product-fields';
import { ProductStatusesRestService } from 'platform-unit2-api/product-statuses';
import { ProductStoriesRestService, UpdateProductStory } from 'platform-unit2-api/product-stories';
import { CreateProductVariant } from 'platform-unit2-api/variants';
import { ProductVariantRestService, ProductVariant } from 'platform-unit2-api/product-variants';
import {
  ProductsRestService,
  CreateProductRequest,
  Product,
  ProductCompact,
  UpdateProductField,
  UpdateProductRequest,
  UpdateProductUpload,
  ErrorProductFieldData,
} from 'platform-unit2-api/products';
import { UploadsRestService, Upload } from 'platform-unit2-api/uploads';
import { VariantsRestService } from 'platform-unit2-api/variants';
import { ListResponse, MetaGtinResponse, PaginationObject } from 'platform-unit2-api/core';
import { ActionTree } from 'vuex';
import { ProductsState, TableColumn } from '../products.types';
import { DataTableFilterInterface } from '@/general/services/data-table-filter-service/data-table-filter.service';
import ProductFilters from 'supplier/modules/products/ts/product-data-table-filter-utils';
import { Defaults } from '@/general/utils/constants/defaults';
import {
  ActionRestService,
  CreateProductVariantsActionRequest,
  UpdateSharedStatus,
} from 'platform-unit2-api/actions';
import { Activity } from 'platform-unit2-api/activities';

const api = new ProductsRestService();
const categoryApi = new CategoryRestService();
const productFieldApi = new ProductFieldsRestService();
const productStoryApi = new ProductStoriesRestService();
const uploadApi = new UploadsRestService();
const variantsApi = new VariantsRestService();
const actionsApi = new ActionRestService();
const categoriesApi = new CategoryRestService();
const productStatusApi = new ProductStatusesRestService();
const productVariantApi = new ProductVariantRestService();
export const actions: ActionTree<ProductsState, {}> = {
  async GET_PRODUCTS(
    { commit, getters },
    {
      pagination: { page, limit, query, sortBy },
      pipelineId,
      excludeIds,
      calendarQueryDate,
      productIds,
      filters,
    },
  ): Promise<ListResponse<Product>> {
    const glnIds = getters.glnFilter;

    const gtins = getters.gtinFilter;

    const brandIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.brand_id?.value?.map(
        (brand) => brand.id,
      ) ?? getters.brandFilter?.map((brand: CrudDataFilterOption) => brand.id);

    const categoryIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.category?.value?.map(
        (group) => group.id,
      ) ?? getters.categoryFilter?.map((group: CrudDataFilterOption) => group.id);

    const dataModelIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.datamodel_id?.value?.map(
        (dm) => dm.id,
      ) ?? getters.dataModelFilter?.map((dm: CrudDataFilterOption) => dm.id);

    const variantIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.variant_name?.value?.map(
        (v) => v.id,
      ) ?? getters.variantFilter?.map((variant: CrudDataFilterOption) => variant.id);

    const moduleIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.module_id?.value?.map((m) => m.id) ??
      getters.moduleFilter?.map((m: CrudDataFilterOption) => m.id);

    const ownerIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.owner_id?.value?.map((o) => o.id) ??
      getters.ownerFilter?.map((o: CrudDataFilterOption) => o.id);

    const statusLabels =
      (filters as DataTableFilterInterface<ProductFilters>)?.product_status_id?.value?.map(
        (sLab: CrudDataFilterOption) => sLab.name ?? sLab.label,
      ) ?? getters.statusFilter?.map((sLab: CrudDataFilterOption) => sLab.name ?? sLab.label);

    try {
      const products = await api.getAll(
        { page, limit, query, sortBy },
        {
          brand_ids: brandIds,
          category_ids: categoryIds,
          datamodel_ids: dataModelIds,
          variants: variantIds,
          module_ids: moduleIds,
          owner_ids: ownerIds,
          status_labels: statusLabels,
          pipeline_id: pipelineId,
          gtins: gtins,
          include_ids: productIds,
          exclude_ids: excludeIds,
          audit_date: calendarQueryDate.date,
          audit_date_type: calendarQueryDate.type,
          gln_ids: glnIds,
        },
      );

      commit('setProducts', products);
      return products;
    } catch (err) {
      throw err;
    }
  },

  async GET_MISSING_GTINS({ getters }, { productIds }): Promise<MetaGtinResponse> {
    const glnIds = getters.glnFilter;

    const brandIds = getters.brandFilter.map((brand: CrudDataFilterOption) => {
      return brand.id;
    });

    const categoryIds = getters.categoryFilter.map((group: CrudDataFilterOption) => {
      return group.id;
    });

    const dataModelIds = getters.dataModelFilter.map((datamodel: CrudDataFilterOption) => {
      return datamodel.id;
    });

    const variantIds = getters.variantFilter?.map((group: CrudDataFilterOption) => {
      return group.id;
    });

    const gtins = getters.gtinFilter;

    const ownerIds = getters.ownerFilter.map((item: CrudDataFilterOption) => {
      return item.id;
    });

    const statusLabels = getters.statusFilter?.map((item: CrudDataFilterOption) => {
      return item.name ? item.name : item.label;
    });

    try {
      return await api.getMissingGtins({
        brand_ids: brandIds,
        category_ids: categoryIds,
        datamodel_ids: dataModelIds,
        variants: variantIds,
        product_ids: productIds,
        gtins: gtins,
        owner_ids: ownerIds,
        status_labels: statusLabels,
        gln_ids: glnIds,
      } as Partial<PaginationObject & ProductFilters>);
    } catch (err) {
      throw err;
    }
  },

  async GET_PRODUCTS_NO_COMMIT(
    { getters },
    {
      pagination: { page, limit, query, sortBy },
      pipelineId,
      excludeIds,
      calendarQueryDate,
      filters,
    },
  ): Promise<ListResponse<Product>> {
    const glnIds = getters.glnFilter;
    const gtins = getters.gtinFilter;

    const brandIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.brand_id?.value?.map(
        (brand) => brand.id,
      ) ?? getters.brandFilter?.map((brand: CrudDataFilterOption) => brand.id);

    const categoryIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.category?.value?.map(
        (group) => group.id,
      ) ?? getters.categoryFilter?.map((group: CrudDataFilterOption) => group.id);

    const dataModelIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.datamodel_id?.value?.map(
        (dm) => dm.id,
      ) ?? getters.dataModelFilter?.map((dm: CrudDataFilterOption) => dm.id);

    const variantIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.variant_name?.value?.map(
        (v) => v.id,
      ) ?? getters.variantFilter?.map((variant: CrudDataFilterOption) => variant.id);

    const moduleIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.module_id?.value?.map((m) => m.id) ??
      getters.moduleFilter?.map((m: CrudDataFilterOption) => m.id);

    const ownerIds =
      (filters as DataTableFilterInterface<ProductFilters>)?.owner_id?.value?.map((o) => o.id) ??
      getters.ownerFilter?.map((o: CrudDataFilterOption) => o.id);

    const statusLabels =
      (filters as DataTableFilterInterface<ProductFilters>)?.product_status_id?.value?.map(
        (sLab: CrudDataFilterOption) => sLab.name ?? sLab.label,
      ) ?? getters.statusFilter?.map((sLab: CrudDataFilterOption) => sLab.name ?? sLab.label);
    try {
      const products = await api.getAll(
        {
          page: page,
          limit: limit,
          query: query,
          sortBy: sortBy,
        },
        {
          brand_ids: brandIds,
          category_ids: categoryIds,
          datamodel_ids: dataModelIds,
          variants: variantIds,
          pipeline_id: pipelineId,
          gtins: gtins,
          module_ids: moduleIds,
          owner_ids: ownerIds,
          exclude_ids: excludeIds,
          status_labels: statusLabels,
          audit_date: calendarQueryDate.date,
          audit_date_type: calendarQueryDate.type,
          gln_ids: glnIds,
        },
      );
      return products;
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_PRODUCTS(
    {},
    { page, limit = Defaults.REQUEST_LIMIT, query, sortBy }: PaginationObject,
  ): Promise<ListResponse<Product>> {
    try {
      return await api.getAll(
        {
          page: page,
          limit: limit,
          query: query,
          sortBy: sortBy,
        },
        {
          variants: ['Master Data'],
        },
      );
    } catch (err) {
      throw err;
    }
  },

  UPDATE_PAGINATION({ commit }, pagination: PaginationObject) {
    commit('setOverviewPagination', pagination);
  },

  UPDATE_BRAND_FILTER({ commit }, filter: CrudDataFilterOption[]) {
    commit('setBrandFilter', filter);
  },

  UPDATE_STATUS_FILTER({ commit }, filter: CrudDataFilterOption[]) {
    commit('setStatusFilter', filter);
  },

  UPDATE_CATEGORY_FILTER({ commit }, filter: CrudDataFilterOption[]) {
    commit('setCategoryFilter', filter);
  },

  UPDATE_DATAMODEL_FILTER({ commit }, filter: CrudDataFilterOption[]) {
    commit('setDataModelFilter', filter);
  },

  UPDATE_VARIANT_FILTER({ commit }, filter: CrudDataFilterOption[]) {
    commit('setVariantFilter', filter);
  },

  UPDATE_OWNER_FILTER({ commit }, filter: any[]) {
    commit('setOwnerFilter', filter);
  },

  UPDATE_MODULE_FILTER({ commit }, filter: any[]) {
    commit('setModuleFilter', filter);
  },

  UPDATE_GTIN_FILTER({ commit }, filter: []) {
    commit('setGtinFilter', filter);
  },

  UPDATE_GLN_FILTER({ commit }, filter: any[]) {
    commit('setGlnFilter', filter);
  },

  UPDATE_ID_FILTER({ commit }, filter: []) {
    commit('setIdFilter', filter);
  },
  UPDATE_IMAGES_INCLUDED({ commit }, filter: Upload[]) {
    commit('setImagesIncluded', filter);
  },

  RESET_FILTERS({ commit }) {
    commit('setBrandFilter', []);
    commit('setCategoryFilter', []);
    commit('setDataModelFilter', []);
    commit('setVariantFilter', null);
    commit('setOwnerFilter', []);
    commit('setGtinFilter', []);
    commit('setIdFilter', []);
    commit('setStatusFilter', []);
    commit('setModuleFilter', []);
    commit('setImagesIncluded', []);
    commit('setImagesIncluded', []);
  },

  async GET_PRODUCT_DETAILS({ commit, dispatch }, productId: number) {
    commit('setLoadingProduct', productId);

    // Make sure to reset editing field data when a different product is opened.
    dispatch('CANCEL_EDITING_FIELD_DATA');

    // Check if requested product is stored as next product.

    // Next product is not requested product
    let product;
    try {
      product = await api.get(productId);
    } catch (err) {
      throw err;
    }

    commit('setProduct', product);

    // Set reset product to be a clone of current product
    const resetProduct = Object.assign({}, product);
    commit('setResetProduct', resetProduct);
    commit('setLoadingProduct', null);
    return product;
  },

  async GET_PRODUCT_DATAMODELS({ commit }, productId: number) {
    try {
      if (!productId) return;

      const datamodels = await api.getProductDatamodels(productId);
      commit('setProductDatamodels', datamodels);
      return datamodels;
    } catch (err) {
      throw err;
    }
  },

  async GET_PRODUCT_CATEGORY_ATTRIBUTES({}, categoryId: number) {
    try {
      return await categoryApi.getProductCategoryAttributes(categoryId);
    } catch (err) {
      throw err;
    }
  },

  async GET_DATAMODEL_FIELDS(
    {},
    { productId, datamodelId }: { productId: number; datamodelId: number },
  ) {
    return await api.getAttributes(productId, datamodelId);
  },

  async GET_PRODUCT_STORIES({}, productId: number) {
    try {
      return await api.getProductStories(productId);
    } catch (err) {
      throw err;
    }
  },

  async GET_PRODUCT_ASSETS({}, productId: number) {
    try {
      return await api.getProductAssets(productId);
    } catch (err) {
      throw err;
    }
  },

  async GET_PRODUCT_FIELDS({}, { productId, locales }: { productId: number; locales: number[] }) {
    try {
      if (!productId) return;

      return await productFieldApi.getProductFields(productId, locales);
    } catch (err) {
      throw err;
    }
  },

  async UPDATE_PRODUCT_ASSETS(
    {},
    { productId, updateUpload }: { productId: number; updateUpload: UpdateProductUpload },
  ) {
    try {
      await api.updateProductAsset(productId, updateUpload);
    } catch (err) {
      throw err;
    }
  },

  async UPDATE_UPLOAD_ORDER(
    { dispatch },
    { productId, uploadId, data }: { productId: number; uploadId: number; data: { order: number } },
  ): Promise<void> {
    try {
      await api.orderProductUpload(productId, uploadId, data);
      dispatch('GET_PRODUCT_DETAILS', productId);
    } catch (err) {
      throw err;
    }
  },

  async DETACH_UPLOAD(
    { dispatch },
    { product, uploadIds }: { product: Product; uploadIds: number[] },
  ) {
    try {
      await api.detachUploads(product.id, uploadIds);
      if (product.thumbnail?.id != null && uploadIds.includes(product.thumbnail?.id)) {
        dispatch('GET_PRODUCT_DETAILS', product.id);
      }
    } catch (err) {
      throw err;
    }
  },

  async ATTACH_UPLOAD(
    { dispatch },
    { productId, uploadId }: { productId: number; uploadId: number },
  ) {
    try {
      await api.attachUpload(productId, uploadId);
      dispatch('GET_PRODUCT_DETAILS', productId);
    } catch (err) {
      throw err;
    }
  },

  async ATTACH_MANY(
    { dispatch },
    { productIds, uploadIds }: { productIds: number[]; uploadIds: number[] },
  ) {
    try {
      await uploadApi.attachManyUploadsToProduct(productIds, uploadIds);
      dispatch('GET_PRODUCT_DETAILS', productIds[0]);
    } catch (err) {
      throw err;
    }
  },

  async GET_PRODUCT_VARIANTS({ commit }, productUuid: string) {
    try {
      commit('setCurrentProductVariants', null);
      const variants = await variantsApi.getProductVariants(productUuid);
      commit('setCurrentProductVariants', variants);
      return variants;
    } catch (err) {
      throw err;
    }
  },

  async UPDATE_PRODUCT_STORY(
    {},
    { story, storyId }: { story: UpdateProductStory; storyId: number },
  ) {
    try {
      await productStoryApi.update(storyId, story);
    } catch (err) {
      throw err;
    }
  },

  async CREATE_PRODUCT_STORY({}, story: UpdateProductStory) {
    try {
      await productStoryApi.post(story);
    } catch (err) {
      throw err;
    }
  },

  async DELETE_PRODUCT_STORY({}, storyId: number) {
    try {
      await productStoryApi.delete(storyId);
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_PRODUCT_VARIANTS(
    {},
    { page, limit = Defaults.REQUEST_LIMIT, query, sortBy }: PaginationObject,
  ): Promise<ListResponse<ProductVariant>> {
    try {
      return await productVariantApi.searchProductVariants({ page, limit, query, sortBy });
    } catch (err) {
      throw err;
    }
  },

  CLEAR_SINGLE_EDITING_FIELD_DATA({ commit, getters }, attributeId: number) {
    const newEditingFields = (getters.editingProductFields as UpdateProductField[]).filter(
      (ef) => ef.attribute_id !== attributeId,
    );
    commit('setEditingProductFields', newEditingFields);
  },

  SET_EDITING_FIELD_DATA({ commit, getters }, updatedFieldData: UpdateProductField) {
    const newEditingFields = getters.editingProductFields;
    const field = newEditingFields.find((editingField: UpdateProductField) => {
      return (
        editingField.attribute_id === updatedFieldData.attribute_id &&
        editingField.locale_id === updatedFieldData.locale_id &&
        editingField.path === updatedFieldData.path
      );
    });

    if (field) {
      if (updatedFieldData.delete_paths) {
        field.delete_paths = [...(field.delete_paths ?? []), ...updatedFieldData.delete_paths];
      } else if (updatedFieldData.reorder_paths) {
        field.reorder_paths = updatedFieldData.reorder_paths ?? [];
      } else {
        field.value = updatedFieldData.value;
      }
    } else {
      newEditingFields.push(updatedFieldData);
    }

    commit('setEditingProductFields', newEditingFields);
  },

  REMOVE_ERROR_FIELD_DATA({ commit, getters }, errorFieldData: ErrorProductFieldData) {
    //remove from ErrorDataset
    const newErrorFields: ErrorProductFieldData[] = getters.errorProductFields;

    const fieldError = newErrorFields.find((editingField: ErrorProductFieldData) => {
      return (
        editingField.attribute_id === errorFieldData.attribute_id &&
        editingField.locale_id === errorFieldData.locale_id
      );
    });
    if (fieldError) {
      newErrorFields.splice(newErrorFields.indexOf(fieldError), 1);
    }

    commit('setErrorProductFields', newErrorFields);
  },

  UPDATE_ERROR_FIELD_DATA({ commit, getters }, params: { errorFieldData: ErrorProductFieldData }) {
    const newErrorFields: ErrorProductFieldData[] = getters.errorProductFields;

    const field = newErrorFields.find((editingField: ErrorProductFieldData) => {
      return (
        editingField.attribute_id === params.errorFieldData.attribute_id &&
        editingField.locale_id === params.errorFieldData.locale_id
      );
    });

    // push to set
    if (!field) {
      newErrorFields.push(params.errorFieldData);
    }

    commit('setErrorProductFields', newErrorFields);
  },

  CANCEL_EDITING_FIELD_DATA({ commit }) {
    commit('setEditingProductFields', []);
    commit('setErrorProductFields', []);
  },

  RESET_PRODUCT({ commit, getters }) {
    const resetProduct = JSON.parse(JSON.stringify(getters.currentResetProduct));
    commit('setProduct', resetProduct);
    commit('setEditingProductFields', []);
    commit('setErrorProductFields', []);
  },

  async SAVE_EDITING_PRODUCT_FIELDS(
    { getters },
    { attributes = [], variants = [] }: { attributes: number[]; variants: number[] },
  ) {
    if (getters.currentProduct) {
      try {
        const fields = getters.editingProductFields.map((field: UpdateProductField) => {
          if (attributes.includes(field.attribute_id)) {
            field.overwrite = true;
          }

          return field;
        });

        const variantsArray = variants.map((num: number) => {
          return num;
        });
        await api.setProductFields(getters.currentProduct.id, fields, variantsArray);
      } catch (err) {
        throw err;
      }
    } else {
      throw new Error();
    }
  },

  async CREATE_PRODUCT({}, data: CreateProductRequest): Promise<ProductCompact> {
    try {
      return await api.post(data);
    } catch (err) {
      throw err;
    }
  },

  async UPDATE_PRODUCT(
    { commit },
    { productId, data }: { productId: number; data: UpdateProductRequest },
  ): Promise<Product> {
    try {
      const product = await api.update(productId, data);
      commit('setProduct', product);
      return product as Product;
    } catch (err) {
      throw err;
    }
  },

  async PUBLISH_PRODUCT({ commit, getters }, productId: number): Promise<void> {
    try {
      await api.publishProduct(productId);

      const product = getters.currentProduct;
      product.published = true;
      commit('setProduct', product);

      const resetProduct = JSON.parse(JSON.stringify(product));
      commit('setResetProduct', resetProduct);
    } catch (err) {
      throw err;
    }
  },

  async PUBLISH_PRODUCT_ID({}, productId: number): Promise<void> {
    await api.publishProduct(productId);
  },

  async CREATE_PRODUCT_VARIANT(
    {},
    { productId, data }: { productId: number; data: CreateProductVariant },
  ): Promise<Product> {
    try {
      return await variantsApi.createProductVariant(productId, data);
    } catch (err) {
      throw err;
    }
  },

  async MASS_CREATE_PRODUCT_VARIANT(
    {},
    { data }: { data: CreateProductVariantsActionRequest },
  ): Promise<void> {
    try {
      return await actionsApi.massCreateProductVariant(data);
    } catch (err) {
      throw err;
    }
  },

  CLEAR_PRODUCT({ commit }) {
    // commit('setProduct', null);
    commit('setResetProduct', null);
    commit('setEditingProductFields', []);
  },

  LEAVE_DETAIL_PAGE({ commit }) {
    commit('setCurrentProduct', undefined);

    commit('setResetProduct', undefined);
    commit('setEditingProductFields', []);
    commit('setPreviousProduct', undefined);
    commit('setNextProduct', undefined);

    commit('setProductDatamodels', []);
    commit('setCurrentProductVariants', []);
    commit('setErrorProductFields', undefined);
  },

  async DELETE_PRODUCT({}, id: number) {
    try {
      await api.delete(id);
    } catch (err) {
      throw err;
    }
  },

  async DELETE_VARIANT({}, productId) {
    try {
      await variantsApi.deleteVariant(productId);
    } catch (err) {
      throw err;
    }
  },

  SET_LOADING_COMPLETENESS({ commit }, value: boolean) {
    commit('setLoadingCompleteness', value);
  },

  async BULK_ACTION_ASSIGN_TO_CLIENT(store, parameter: { ids: string[]; clientId: number }) {
    try {
      await actionsApi.assignProductsToClient(parameter.clientId, parameter.ids);
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_MASS_UPDATE_FIELDS(
    {},
    parameter: { ids: number[]; locale_id: number; productDefinitionId: number; value: string },
  ) {
    try {
      await actionsApi.bulkUpdateFields(
        parameter.ids,
        [parameter.locale_id],
        parameter.productDefinitionId,
        parameter.value,
      );
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_MASS_SYNC_DISPLAY_NAME(
    {},
    parameter: { ids: number[]; locale_id: number; productDefinitionId: number },
  ) {
    try {
      await actionsApi.syncDisplayName(
        parameter.ids,
        parameter.locale_id,
        parameter.productDefinitionId,
      );
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_MASS_SYNC_BRAND(
    {},
    parameter: { ids: number[]; locale_id: number; productDefinitionId: number },
  ) {
    try {
      await actionsApi.syncBrand(parameter.ids, parameter.locale_id, parameter.productDefinitionId);
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_DELETE(_, ids: number[]) {
    try {
      await actionsApi.deleteProducts(ids);
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_DELETE_LOCALE_CONTENT(
    {},
    parameter: { productIds: number[]; localeId: number },
  ) {
    try {
      await actionsApi.deleteLocaleContent(parameter.productIds, parameter.localeId);
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_TRANSITION_STATE(
    {},
    parameter: { productIds: number[]; productStatus: string },
  ) {
    try {
      await actionsApi.transitionState(parameter.productIds, parameter.productStatus);
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_DETACH_ASSETS(
    {},
    parameter: { ids: number[]; includeVariants: boolean; exceptThumbnail: boolean },
  ) {
    try {
      await actionsApi.detachAssets(
        parameter.ids,
        parameter.includeVariants,
        parameter.exceptThumbnail,
      );
    } catch (err) {
      throw err;
    }
  },
  async BULK_ACTION_LOCK_FIELDS(
    {},
    parameter: { ids: number[]; localeId: number; field_ids: number[]; lock: boolean },
  ) {
    try {
      await actionsApi.lockAllFields(
        parameter.ids,
        parameter.localeId,
        parameter.field_ids,
        parameter.lock,
      );
    } catch (err) {
      throw err;
    }
  },

  async BULK_ACTION_SHARE_PRODUCTS(
    {},
    parameter: { ids: number[]; email: string; message: string },
  ) {
    try {
      await actionsApi.shareProducts(parameter.ids, parameter.email, parameter.message);
    } catch (err) {
      throw err;
    }
  },

  async ATTACH_TO_TASK({}, parameter: { ids: number[]; actionId: number }) {
    try {
      await actionsApi.attachProductsToTask(parameter.ids, parameter.actionId);
    } catch (err) {
      throw err;
    }
  },

  async TOGGLE_PRODUCT_FIELD_LOCK_STATE({}, productField: ProductField) {
    productField.locked = !productField.locked;
    try {
      await productFieldApi.setFieldLockState(
        productField.id,
        productField.locked,
        productField.locale_id,
      );
    } catch (err) {
      productField.locked = !productField.locked;
      throw err;
    }
  },

  async SET_NEXT_PRODUCT({ commit }, product: Product) {
    commit('setNextProduct', product);
  },

  async SET_PREVIOUS_PRODUCT({ commit }, product: Product) {
    commit('setPreviousProduct', product);
  },

  async SEARCH_VARIANTS(
    {},
    { page, limit = Defaults.REQUEST_LIMIT, query, sortBy }: PaginationObject,
  ) {
    try {
      return (
        await api.getVariants({
          page: page,
          limit: limit,
          query: query ?? '',
          sortBy: sortBy ?? '',
        })
      ).data;
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_OWNERS({}, { query }: PaginationObject) {
    try {
      return await api.searchProductsOwners({
        page: 1,
        limit: 100,
        query: query ?? '',
        sortBy: '',
      });
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_BRANDS({}, { query }: PaginationObject) {
    try {
      return await api.searchProductsBrands({
        page: 1,
        limit: 100,
        query: query ?? '',
        sortBy: '',
      });
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_DATAMODELS({}, { query }: PaginationObject) {
    try {
      return await api.searchProductsDatamodels({
        page: 1,
        limit: 100,
        query: query ?? '',
        sortBy: '',
      });
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_CATEGORIES({}, { query }: PaginationObject) {
    try {
      return await categoriesApi.getAll({
        page: 1,
        limit: 100,
        query: query ?? '',
        sortBy: '',
      });
    } catch (err) {
      throw err;
    }
  },

  async SEARCH_MODULES({}, { query }: PaginationObject) {
    try {
      return await api.searchProductModules({
        page: 1,
        limit: 100,
        query: query ?? '',
        sortBy: '',
      });
    } catch (err) {
      throw err;
    }
  },

  async GET_ALLOWED_TO_PUBLISH({}, productIds: number[]) {
    try {
      return await api.allowedToPublish(productIds);
    } catch (err) {
      throw err;
    }
  },

  async GET_ALL_STATUSES({}) {
    try {
      return (await productStatusApi.getAll()).data;
    } catch (err) {
      throw err;
    }
  },

  async SET_COLUMNS({ commit }, columns: TableColumn[]) {
    commit('setColumns', columns);
  },

  async SAVE_SEARCH({ commit }, query: string) {
    try {
      commit('setSearch', query);
    } catch (err) {
      throw err;
    }
  },

  UPDATE_INITIALIZED({ commit }, value: boolean) {
    commit('setInitialized', value);
  },

  SET_MISSING_FIELDS({ commit, getters }) {
    commit('setMissingFields', !getters.showMissingFields);
  },

  SET_SELECTED_LOCALES({ commit }, locales: Locale[]) {
    commit('setSelectedLocales', locales);
  },

  SET_COPY_FROM_LANGUAGE({ commit }, status: boolean) {
    commit('setCopyFromLanguage', status);
  },

  async ADD_CATEGORIES_TO_PRODUCT({}, parameter: { productId: number; categories: number[] }) {
    try {
      return await api.addCategoriesToProduct(parameter.productId, parameter.categories);
    } catch (err) {
      throw err;
    }
  },

  async GET_ATTACHED_CATEGORIES({}, productId: number) {
    try {
      return await api.getAttachedCategories(productId);
    } catch (err) {
      throw err;
    }
  },

  async SHARE_WITH_RETAILER({}, products: UpdateSharedStatus) {
    try {
      await actionsApi.shareWithRetailer(products);
    } catch (err) {
      throw err;
    }
  },

  async UNSHARE_WITH_RETAILER({}, products: UpdateSharedStatus) {
    try {
      await actionsApi.unshareWithRetailer(products);
    } catch (err) {
      throw err;
    }
  },

  async GET_ACTIVITIES({}, parameters: PaginationObject & { productId: number }) {
    const activities = await api.getProductActivities(parameters.productId, {
      page: 1,
      limit: Defaults.REQUEST_LIMIT,
      query: '',
      sortBy: '',
      ...parameters,
    });

    //Todo: move the transformation logic to utils
    /** Grouping activities by date and user */
    const transformedActivities = activities.data.reduce(
      (grouped: Record<string, Activity[]>, activity: Activity) => {
        const { updated_at: updatedAt, user } = activity;
        grouped[updatedAt + user] = grouped[updatedAt + user] ?? [];
        grouped[updatedAt + user].push(activity);

        return grouped;
      },
      {},
    );
    const activityArray = Object.values(transformedActivities);
    //Remove completeness updates from the array
    const finalArray = activityArray.map((activities) => {
      return activities.filter((activity) => !activity.modified?.completeness);
    });

    //To reurn the same structure as <ListResponse>
    return { data: finalArray, meta: activities.meta };
  },

  async SET_FIELDS({ commit }, fields: ProductField) {
    commit('setProductFields', fields);
  },

  SET_CURRENT_PRODUCT({ commit }, product: Product) {
    commit('setCurrentProduct', product);
  },

  SET_PRODUCTS({ commit }, product: Product[]) {
    commit('setProducts', product);
  },

  SET_PAGINATION({ commit }, pagination: PaginationObject) {
    commit('setOverviewPagination', pagination);
  },

  SET_VALIDATION_STATUS({ commit }, status: boolean) {
    commit('setShouldRunAttributesValidation', status);
  },

  SET_VALIDATION_ERRORS({ commit }, rules: any) {
    commit('setValidationErrors', rules);
  },
};
