<script setup lang="ts">
// import ProductMetaEdit from 'retailer/modules/products/components/product-meta-edit.vue';
import ProductMetaLinkProducts from 'retailer/modules/products/components/product-meta-link-products.vue';
import ProductTasks from 'retailer/modules/products/components/product-tasks.vue';
import ProductPreview from 'retailer/modules/products/components/product-preview.vue';
import useStorage from 'utils/storage';

import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';

import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { LocaleDetails } from 'retailer/modules/locales/locales.types';
import { onMounted, onBeforeMount, ref, watch, computed, inject } from 'vue';
import Activites from 'retailer/modules/activities/views/activities.vue';
import { Product } from 'platform-unit2-api/products';
import { LanguageMenu } from '../ts/products.types';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { formatDate } from '@/general/utils/format-date';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

/** Consts */
const store = useStore();
const router = useRouter();
const route = useRoute();
const storage = useStorage();

const showPreviousProduct = ref(false);
const showNextProduct = ref(false);
const locales = ref<LocaleDetails[]>([]);
const languages = ref<LanguageMenu[]>([]);

const metaLinkProductsActive = ref(false);
const metaLinkProductsVisible = (value: boolean) => {
  metaLinkProductsActive.value = value;
};

const tasksActive = ref(false);
const tasksVisible = (value: boolean) => {
  tasksActive.value = value;
};

const actions = ref([
  {
    label: ts.tModule('product_details.link_products.menu'),
    icon: 'mdi mdi-link',
    command: () => metaLinkProductsVisible(true),
  },
]);

const getNextProduct = async () => {
  showNextProduct.value = false;

  //here clean the previous next product from the store
  store.dispatch('products/SET_NEXT_PRODUCT', null);

  //here we find the current product's index the the list of products
  const index = storage
    .getStorageItem('products')
    .data.findIndex((item: Product) => item.id === storage.getStorageItem('currentProduct').id);

  //if we are at the end of our pagination get the first element of the next page
  if (storage.getStorageItem('products').data.length === index + 1) {
    //get all the products of the next page
    const newProducts = (
      await store.dispatch('products/GET_PRODUCTS_NO_COMMIT', {
        pagination: {
          page: storage.getStorageItem('overviewPagination').page + 1,
          limit: storage.getStorageItem('overviewPagination').limit,
          query: storage.getStorageItem('overviewPagination').query,
          sortBy: storage.getStorageItem('overviewPagination').sortBy,
        },
      })
    ).data;

    //if we have products in the next page set the next product
    //else we set the next product to null
    await store.dispatch(
      'products/SET_NEXT_PRODUCT',
      newProducts.length > 0 ? newProducts[0] : null,
    );
  } else {
    //here we set the next product to the next product in list list
    store.dispatch('products/SET_NEXT_PRODUCT', storage.getStorageItem('products').data[index + 1]);
  }
};

const openNextProduct = async () => {
  if (!productAttributeFieldService.value.dirtyStateService.isTouched()) {
    productAttributeFieldService.value.loading = true;
    //find the current product's index in the list of products
    const index = storage
      .getStorageItem('products')
      .data.findIndex((item: Product) => item.id === storage.getStorageItem('currentProduct').id);

    if (index === storage.getStorageItem('overviewPagination').limit - 1) {
      //here we update the pagination
      const pagination = storage.getStorageItem('overviewPagination');
      pagination.page = pagination.page + 1;
      storage.setStorageItem('overviewPagination', pagination);

      //a side effect of this function is that it will commit the products to the store.
      storage.setStorageItem(
        'products',
        await store.dispatch('products/GET_PRODUCTS', {
          pagination: pagination,
        }),
      );

      store.dispatch('products/SET_CURRENT_PRODUCT', store.getters['products/nextProduct']);
      storage.setStorageItem('currentProduct', store.getters['products/nextProduct']);

      router.push({
        name: 'product-details',
        params: {
          id: store.getters['products/nextProduct']!.id.toString(),
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    }

    return;
  }

  productAttributeFieldService.value.dirtyStateService.showDirtyDialog(
    async () => {
      productAttributeFieldService.value.discardChanges();
      productAttributeFieldService.value.loading = true;

      //find the current product's index in the list of products
      const index = storage
        .getStorageItem('products')
        .data.findIndex((item: Product) => item.id === storage.getStorageItem('currentProduct').id);

      if (index === storage.getStorageItem('overviewPagination').limit - 1) {
        //here we update the pagination
        const pagination = storage.getStorageItem('overviewPagination');
        pagination.page = pagination.page + 1;
        storage.setStorageItem('overviewPagination', pagination);

        //a side effect of this function is that it will commit the products to the store.
        storage.setStorageItem(
          'products',
          await store.dispatch('products/GET_PRODUCTS', {
            pagination: pagination,
          }),
        );
      }

      store.dispatch('products/SET_CURRENT_PRODUCT', store.getters['products/nextProduct']);
      storage.setStorageItem('currentProduct', store.getters['products/nextProduct']);
      productAttributeFieldService.value.currentProduct = store.getters['products/nextProduct'];

      router.push({
        name: 'product-details',
        params: {
          id: store.getters['products/nextProduct']!.id.toString(),
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    },
    () => {
      return;
    },

    () => {},
    'dirty-state',
  );
};

const addLanguage = (locale: LocaleDetails) => {
  let selectedLocales: LocaleDetails[] = store.getters['products/selectedLocales'];
  selectedLocales.length === 1 && selectedLocales[0].id === locale.id //if the user wants to deselect the only selected language
    ? toastService.displaySuccessToast(
        ts.tModule('product_details.product_action_group.removing_not_allowed'),
      )
    : selectedLocales.find((existedLocal) => existedLocal.id === locale.id) === undefined
    ? selectedLocales.push(locale)
    : (selectedLocales = selectedLocales.filter((existedLocale) => existedLocale.id !== locale.id));
  store.dispatch('products/SET_SELECTED_LOCALES', selectedLocales);
};

const getPreviousProduct = async () => {
  showPreviousProduct.value = false;
  store.dispatch('products/SET_PREVIOUS_PRODUCT', null);

  //find the current product's index in the list of products
  const index = storage
    .getStorageItem('products')
    .data.findIndex((item: Product) => item.id === storage.getStorageItem('currentProduct').id);

  //if we are on the first page and the index is 0 we leave the previous product to null
  if (storage.getStorageItem('overviewPagination')?.page === 1 && index === 0) {
    return;
  }

  //if we are at the beginning of our pagination we will find the last
  //element of our previous page
  if (index === 0) {
    //get the elements of our previous page
    const newProducts = (
      await store.dispatch('products/GET_PRODUCTS_NO_COMMIT', {
        pagination: {
          page: storage.getStorageItem('overviewPagination').page - 1,
          limit: storage.getStorageItem('overviewPagination').limit,
          query: storage.getStorageItem('overviewPagination').query,
          sortBy: storage.getStorageItem('overviewPagination').sortBy,
        },
      })
    ).data;

    // set the product the the las element of the previous page
    store.dispatch('products/SET_PREVIOUS_PRODUCT', newProducts[newProducts.length - 1]);
  } else {
    //set the previous product to the previous element of the list
    store.dispatch(
      'products/SET_PREVIOUS_PRODUCT',
      storage.getStorageItem('products')?.data[index - 1],
    );
  }
};

const openPrevProduct = async () => {
  if (!productAttributeFieldService.value.dirtyStateService.isTouched()) {
    productAttributeFieldService.value.loading = true;

    //find the current product's index in the list of products
    const index = storage
      .getStorageItem('products')
      .data.findIndex((item: Product) => item.id === storage.getStorageItem('currentProduct').id);

    if (index === 0) {
      //here we update the pagination
      const pagination = storage.getStorageItem('overviewPagination');
      pagination.page = pagination.page - 1;
      storage.setStorageItem('overviewPagination', pagination);

      //a side effect of this function is that it will commit the products to the store.
      storage.setStorageItem(
        'products',
        await store.dispatch('products/GET_PRODUCTS', {
          pagination: pagination,
        }),
      );
    }

    //set the current product to the previous product
    store.dispatch('products/SET_CURRENT_PRODUCT', store.getters['products/previousProduct']);
    storage.setStorageItem('currentProduct', store.getters['products/previousProduct']);

    await router.push({
      name: 'product-details',
      params: {
        id: store.getters['products/previousProduct']!.id.toString(),
      } as RouteParamsRaw,
    } as RouteLocationRaw);
    return;
  }

  productAttributeFieldService.value.dirtyStateService.showDirtyDialog(
    async () => {
      productAttributeFieldService.value.discardChanges();
      productAttributeFieldService.value.loading = true;
      //find the current product's index in the list of products
      const index = storage
        .getStorageItem('products')
        .data.findIndex((item: Product) => item.id === storage.getStorageItem('currentProduct').id);

      if (index === 0) {
        //here we update the pagination
        const pagination = storage.getStorageItem('overviewPagination');
        pagination.page = pagination.page - 1;
        storage.setStorageItem('overviewPagination', pagination);

        //a side effect of this function is that it will commit the products to the store.
        storage.setStorageItem(
          'products',
          await store.dispatch('products/GET_PRODUCTS', {
            pagination: pagination,
          }),
        );
      }

      //set the current product to the previous product
      store.dispatch('products/SET_CURRENT_PRODUCT', store.getters['products/previousProduct']);
      storage.setStorageItem('currentProduct', store.getters['products/previousProduct']);

      productAttributeFieldService.value.currentProduct = store.getters['products/previousProduct'];
      await router.push({
        name: 'product-details',
        params: {
          id: store.getters['products/previousProduct']!.id.toString(),
        } as RouteParamsRaw,
      } as RouteLocationRaw);
    },
    () => {
      return;
    },
    () => {},
    'dirty-state',
  );
};

watch(
  () => store.getters['products/currentProduct'],
  async () => {
    if (store.getters['products/currentProduct']) {
      await getNextProduct();
      await getPreviousProduct();
    }
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  try {
    //if the store is empty we will fetch the products from the local storage
    //otherwise we will save the products from the store to the local storage
    if (
      store.getters['products/currentProduct'] &&
      store.getters['products/products'] &&
      store.getters['products/overviewPagination']
    ) {
      storage.setStorageItem(
        'currentProduct',
        await store.dispatch('products/GET_PRODUCT_DETAILS', route.params.id),
      );
      storage.setStorageItem('products', store.getters['products/products']);
      storage.setStorageItem('overviewPagination', store.getters['products/overviewPagination']);
    } else if (
      storage.getStorageItem('currentProduct') &&
      storage.getStorageItem('products') &&
      storage.getStorageItem('overviewPagination')
    ) {
      store.dispatch('products/SET_CURRENT_PRODUCT', storage.getStorageItem('currentProduct'));
      store.dispatch('products/SET_PRODUCTS', storage.getStorageItem('products'));
      store.dispatch('products/SET_PAGINATION', storage.getStorageItem('overviewPagination'));
    }
  } catch {
    //silently fail, this is because the user
    //has refreshed the page and the store is empty
  }

  await getNextProduct();
  await getPreviousProduct();
});

onBeforeMount(async () => {
  locales.value = (await store.dispatch('locales/SEARCH_LOCALES', { query: '' })).data;
  locales.value.forEach((locale) =>
    languages.value.push({
      label: locale.language ?? '',
      icon: 'fi fi-' + locale.format + ' fis',
      value: locale,
      command: (e) => {
        addLanguage(e.item.value);
      },
    }),
  );
});

const currentProduct = computed(() => store.getters['products/currentProduct']);
const nextProduct = computed(() => store.getters['products/nextProduct']);
const previousProduct = computed(() => store.getters['products/previousProduct']);

/** Activity sidebar */
const activitySidebarActive = ref(false);
const activitySidebarVisible = (value: boolean) => {
  activitySidebarActive.value = value;
};
</script>
<template>
  <div>
    <!-- <ProductMetaEdit
      v-if="metaEditActive"
      :current-product="currentProduct"
      :is-active="metaEditActive"
      @hide="metaEditVisible(false)"
    ></ProductMetaEdit> -->

    <ProductMetaLinkProducts
      v-if="metaLinkProductsActive"
      :current-product="currentProduct"
      :is-active="metaLinkProductsActive"
      :excluded-products="[]"
      @hide="metaLinkProductsVisible(false)"
    ></ProductMetaLinkProducts>

    <ProductTasks
      v-if="tasksActive"
      :is-active="tasksActive"
      @hide="tasksVisible(false)"
    ></ProductTasks>

    <!-- Activity sidebar -->
    <Activites
      v-if="activitySidebarActive"
      :is-active="activitySidebarActive"
      module-name="products"
      :module-id="currentProduct.id"
      @hide="activitySidebarVisible(false)"
    >
    </Activites>

    <p-confirm-dialog group="product-delete-modal"></p-confirm-dialog>
    <div class="flex flex-column justify-content-between">
      <div class="align-items-center flex">
        <pButton
          class="mr-2"
          plain
          outlined
          icon="mdi mdi-calendar-check"
          :label="ts.tModule('tasks.title')"
          @click="tasksVisible(true)"
        />
        <div>
          <pButton
            plain
            outlined
            class="mr-2"
            icon="mdi mdi-clock-outline"
            :label="ts.tModule('product_details.product_action_group.activity')"
            @click="activitySidebarVisible(true)"
          />
        </div>
        <pSplitButton
          :label="ts.tGlobal('actions')"
          :model="actions"
          class="mr-2 p-button-plain"
          outlined
        />
        <div class="border-700 border-left-1 h-full inline-flex ml-2 mr-3 w-1px"></div>
        <span class="p-buttonset">
          <pButton
            plain
            outlined
            class="border-noround-right"
            icon="mdi mdi-chevron-left"
            :disabled="!previousProduct ? true : false"
            @mouseover="showPreviousProduct = true"
            @mouseleave="showPreviousProduct = false"
            @click="openPrevProduct"
          />
          <div v-if="showPreviousProduct" class="absolute right-0">
            <ProductPreview :product="previousProduct"></ProductPreview>
          </div>
          <pButton
            plain
            outlined
            class="border-noround-left"
            icon="mdi mdi-chevron-right"
            :disabled="!nextProduct ? true : false"
            @mouseover="showNextProduct = true"
            @mouseleave="showNextProduct = false"
            @click="openNextProduct"
          />
          <div v-if="showNextProduct" class="absolute right-0">
            <ProductPreview :product="nextProduct"></ProductPreview>
          </div>
        </span>
      </div>
      <p class="mt-4 text-right">
        {{ ts.tGlobal('updated_at') }}:
        {{ formatDate(currentProduct.updated_at) }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-splitbutton:deep(.p-splitbutton-defaultbutton),
.p-splitbutton:deep(.p-splitbutton-menubutton) {
  border-color: var(--gray-600);
  color: var(--gray-600);
  cursor: default;
  &:enabled:hover {
    background: white;
    color: var(--gray-600);
  }
  &:enabled:active {
    background: white;
    color: var(--gray-600);
  }
}
.p-splitbutton:deep(.p-splitbutton-menubutton) {
  cursor: pointer;
  &:enabled:hover {
    background: var(--gray-50);
  }
  &:enabled:active {
    background: var(--gray-100);
  }
}
</style>
