<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmDialogProps } from 'primevue/confirmdialog';
import { ref, watch } from 'vue';
import { ConfirmDialogPhraseService } from './confirm-dialog-phrase.service';

interface Props extends ConfirmDialogProps {
  phrase?: string;
  additionalMessage?: string;
  entityNameToDelete: string;
  amountToDelete: number;
}

const props = withDefaults(defineProps<Props>(), {
  additionalMessage: undefined,
  phrase: 'DELETE',
});

//services
const ts = new TranslationService('general', 'components');
const confirmDialogPhraseService = ref(new ConfirmDialogPhraseService(props.phrase));

//refs
const refDialogPhrase = ref<any | undefined>(undefined);

watch(
  () => confirmDialogPhraseService.value.dialogIsVisible,
  () => {
    confirmDialogPhraseService.value.cleanUp();
  },
);

watch(
  () => refDialogPhrase.value,
  () => {
    confirmDialogPhraseService.value.setDialogPhraseRef(refDialogPhrase.value);
  },
);
</script>
<template>
  <pConfirmDialog
    ref="refDialogPhrase"
    v-bind="{ ...props, ...$attrs }"
    @copy.prevent
    @paste.prevent="
      ToastService.getInstance().displayErrorToast(ts.tModule('confirmPopupPhrase.copyPasteError'))
    "
  >
    <template #container="{ acceptCallback, rejectCallback }">
      <div
        class="align-items-center bg-white flex flex-column gap-3 mb-3 p-3 pb-3 surface-border w-full"
      >
        <div class="align-items-center flex flex-row">
          <i class="mdi mdi-alert-outline mr-3 text-3xl text-pink-500"></i>
          <p class="font-bold">
            {{
              ts.tModule('confirmPopupPhrase.header', { params: { entity: entityNameToDelete } })
            }}
          </p>
        </div>
        <p>
          {{
            ts.tModule('confirmPopupPhrase.question', {
              params: { entity: entityNameToDelete },
              choice: amountToDelete,
            })
          }}
        </p>
        <p>
          {{ ts.tModule('confirmPopupPhrase.confirmSentence') }}
          <span class="font-bold text-pink-500">{{ phrase }}</span>
          {{ ts.tModule('confirmPopupPhrase.confirmTypeInformation') }}
        </p>
        <pInputText
          v-model="confirmDialogPhraseService.typedPhrase"
          class="w-full"
          :placeholder="ts.tModule('confirmPopupPhrase.inputPlaceholder')"
        />
        <div v-if="additionalMessage" class="font-italic text-orange-500 text-sm">
          {{ additionalMessage }}
        </div>
        <div class="flex gap-2 justify-content-end mt-3 w-full">
          <pButton
            :label="ts.tGlobal('cancel')"
            outlined
            size="small"
            @click="rejectCallback"
          ></pButton>
          <pButton
            :disabled="!confirmDialogPhraseService.isPhraseMatched"
            :label="ts.tGlobal('delete')"
            severity="danger"
            size="small"
            @click="acceptCallback"
          ></pButton>
        </div>
      </div>
    </template>
  </pConfirmDialog>
</template>
