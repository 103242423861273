import { ProductsState } from 'retailer/modules/products/products.types';
import { actions } from 'retailer/modules/products/store/products.actions';
import { getters } from 'retailer/modules/products/store/products.getters';
import { mutations } from 'retailer/modules/products/store/products.mutations';
import i18n from '@/core/i18n';
import { Module } from 'vuex';

const namespaced = true;
export const state: ProductsState = {
  products: undefined,
  currentProductVariants: [],
  currentProductDatamodels: [],
  errorProductFields: [],
  currentProduct: undefined,
  loadingProduct: undefined,
  nextProduct: undefined,
  previousProduct: undefined,
  currentResetProduct: undefined,
  editingProductFields: [],
  loadingCompleteness: false,
  categoryFilter: [],
  brandFilter: [],
  statusFilter: [],
  dataModelFilter: [],
  variantFilter: [],
  ownerFilter: [],
  moduleFilter: [],
  gtinFilter: [],
  glnFilter: [],
  imagesIncluded: [],
  idFilter: [],
  search: '',
  initialized: false,
  showMissingFields: false,
  overviewPagination: {
    page: 1,
    limit: 15,
    sortBy: '',
    query: '',
  },
  columns: [
    {
      selected: true,
      field: 'gtin',
      header: i18n.global.t('global.gtin'),
      sortable: true,
      type: 'gtin',
      size: '12rem',
      visible: true,
    },
    {
      selected: true,
      field: 'display_name',
      header: i18n.global.t('supplier.products.product_name'),
      sortable: true,
      type: 'display_name',
      size: '24rem',
      visible: true,
    },
    {
      selected: false,
      field: 'images_included',
      header: i18n.global.t('supplier.products.assetsIncluded'),
      sortable: false,
      type: 'images_included',
      size: '17rem',
      visible: true,
    },
    {
      selected: true,
      field: 'variant_name',
      header: i18n.global.t('supplier.products.variant_name'),
      sortable: true,
      filter: true,
      type: 'variant',
      size: '16rem',
      visible: true,
    },
    {
      selected: true,
      field: 'shared_retailer',
      header: i18n.global.t('supplier.products.shared_retailer'),
      sortable: false,
      filter: false,
      type: 'shared_retailer',
      size: '8rem',
      visible: true,
    },
    {
      selected: true,
      field: 'brand_id',
      header: i18n.global.t('supplier.brands.title'),
      sortable: true,
      type: 'brand',
      size: '14rem',
      visible: true,
    },
    {
      selected: true,
      field: 'category',
      header: i18n.global.t('supplier.categories.title'),
      sortable: true,
      type: 'category',
      size: '14rem',
      visible: true,
    },
    {
      selected: true,
      field: 'datamodel_id',
      header: i18n.global.t('supplier.datamodels.title'),
      sortable: true,
      type: 'datamodel',
      size: '16rem',
      visible: true,
    },
    {
      selected: true,
      field: 'owner_id',
      sortable: true,
      header: i18n.global.t('global.owner'),
      type: 'owner',
      size: '12rem',
      visible: true,
    },
    {
      selected: true,
      field: 'module_id',
      header: i18n.global.t('supplier.modules.title'),
      sortable: true,
      type: 'module',
      size: '12rem',
      visible: true,
    },
    {
      selected: true,
      field: 'product_status_id',
      header: i18n.global.t('global.status'),
      sortable: true,
      type: 'status',
      size: '14rem',
      visible: true,
    },
    {
      selected: true,
      field: 'completeness',
      header: i18n.global.t('global.completeness_score'),
      sortable: true,
      type: 'completeness',
      size: '16rem',
      visible: true,
    },
    {
      selected: true,
      field: 'updated_at',
      header: i18n.global.t('global.updated_at'),
      sortable: true,
      type: 'updated_at',
      size: '16rem',
      visible: true,
    },
  ],
  selectedLocales: [],
  copyFromLanguage: false,
  fields: [],
  shouldRunAttributesValidation: false,
  validationErrors: [],
};

export const productsStore: Module<ProductsState, {}> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
