import {
  ImportMappingAdvancedItem,
  ImportMappingAdvancedItemResponse,
  ImportMappingItemResponse,
} from 'platform-unit2-api';
import { TranslationService } from '../../../translations/translation.service';
import { OverviewBase } from '../../interfaces/overview-base.interface';
import { TreeNode } from 'primevue/treenode';
import { ImportMappingItemsViewService } from './import-mapping-items.service';

export class ImportMappingItemsAdvancedViewService
  extends ImportMappingItemsViewService
  implements OverviewBase
{
  private _expandKeys: { [key: string]: boolean } = {};

  public expanded = false;

  public get expandedKeys(): { [key: string]: boolean } {
    return this._expandKeys;
  }

  public set expandedKeys(keys: { [key: string]: boolean }) {
    this._expandKeys = keys;
  }

  private _mappingTree: TreeNode[] = [];
  /**
   * Get mapping items, this.data is assigned to mappings for readabilty.
   */
  public get mappingTree(): TreeNode {
    return this._mappingTree;
  }

  private set mappingTree(items: ImportMappingAdvancedItem[]) {
    this._mappingTree = this._mapAdvancedImportItem(items);
  }

  constructor(ts: TranslationService) {
    super(ts);
  }

  public toggleExpansion(): void {
    if (!this.expanded) {
      const importmappingItems = this._importMappingItems
        .map((item) => [...this.flattenChildren(item), item])
        .flat();

      const expand = importmappingItems.map(function (item) {
        return { [item.attribute.id.toString()]: true };
      });

      this._expandKeys = expand.reduce(function (result, item) {
        return Object.assign(result, item);
      }, {});
    } else {
      Object.keys(this._expandKeys).forEach((key) => {
        this._expandKeys[key] = false;
      });
    }

    this.expanded = !this.expanded;
  }

  public override fetchAll(): void {
    this._isLoadingOverview = true;

    this._mappingItemRestService
      .getImportMappingAdvancedItems(+this._route.params['id'], this.query)
      .then((result) => {
        this.handleResponse(result);

        this._resetDirtyState();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._importMappingItems = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this._isInitialized = true;
      });
  }

  override _importMappingItems: ImportMappingAdvancedItem[] = [];

  override handleResponse = (
    response: ImportMappingItemResponse | ImportMappingAdvancedItemResponse,
  ) => {
    this.mappingTree = response.data as ImportMappingAdvancedItem[];

    this.mappingItems = response.data
      .flatMap((item) => {
        const advancedItem = item as ImportMappingAdvancedItem;

        return [item, ...this.flattenChildren(advancedItem)] as ImportMappingAdvancedItem[];
      })
      .map((item) => {
        item.children = [];
        return item;
      });

    this.amountOfAttributes = response.amount_of_attributes;
    this.amountOfSavedItems = response.amount_of_saved_items;
  };

  private flattenChildren(item: ImportMappingAdvancedItem): ImportMappingAdvancedItem[] {
    if (!item.children) {
      return [];
    }

    return item.children.flatMap((i: ImportMappingAdvancedItem) => {
      return [i, ...this.flattenChildren(i)];
    });
  }

  protected _parseMappingItemsToSyncRequest() {
    return this._editingMappingItems.map(this._parseMappingItemToRequest);
  }

  public updateAdvancedMappingItem(attributeId: number, value: string): void {
    const flatteList =
      this.mappingItems?.flatMap((item) => {
        return [item, ...this.flattenChildren(item as ImportMappingAdvancedItem)];
      }) ?? [];

    const mappingItem = flatteList.find((item) => Number(item.attribute.id) == Number(attributeId));

    super.updateMappingItem(mappingItem!, value);

    this._importMappingItems = this._importMappingItems.map((item) => {
      const editedItem = this._editingMappingItems.find(
        (i) => i.attribute.id === item.attribute.id,
      );

      if (editedItem) {
        const editedItemFromInitialState = this.dirtyState.initialData.find(
          (i) => i.attribute.id === item.attribute.id,
        );

        if (editedItemFromInitialState?.argument === editedItem.argument) {
          this._editingMappingItems = this._editingMappingItems.filter(
            (i) => i.attribute.id !== editedItem.attribute.id,
          );
          return item;
        }

        return editedItem;
      }

      return item as ImportMappingAdvancedItem;
    }) as ImportMappingAdvancedItem[];
  }

  private _mapAdvancedImportItem(items: ImportMappingAdvancedItem[] | null): TreeNode[] {
    if (!items) {
      return [];
    }

    return items.map((item: ImportMappingAdvancedItem) => {
      const editedItem = this._editingMappingItems.find(
        (i) => i.attribute.id === item.attribute.id,
      );

      if (editedItem) {
        return {
          key: item.attribute.id.toString(),
          data: {
            attribute: item.attribute.key,
            argument: editedItem.argument,
            type: item.attribute.type,
          },
          children: this._mapAdvancedImportItem(item.children),
        };
      }

      return {
        key: item.attribute.id.toString(),
        label: item.attribute.key,
        data: {
          attribute: item.attribute.key,
          argument: item.argument,
          type: item.attribute.type,
        },
        children: this._mapAdvancedImportItem(item.children),
      };
    });
  }

  public syncImportMappings() {
    this._isLoadingOverview = true;

    const request = this._parseMappingItemsToSyncRequest();

    this._mappingItemRestService
      .advancedSync(Number(this._route.params['id']), request)
      .then((result) => {
        this.handleResponse(result);

        this._editingMappingItems = [];

        this.query = '';

        this._toastService.displaySuccessToast(this._ts.tModule('syncSuccess'));

        this._resetDirtyState(true);
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
      })
      .finally(() => {
        this._isLoadingOverview = false;
      });
  }
}
